<template>
    <div class="content-error">
        <img src="../assets/error.png" alt="Error">
        <h3 class="error-title">{{ $t('errors.unexpected_error.title') }}</h3>
        <p class="error-description">{{ $t('errors.unexpected_error.description') }}</p>
    </div>
</template>

<script>

</script>

<style scoped>
.content-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.error-title {
    color: #FFB8B1;
    font-size: clamp(0.8rem, 0.30vw + 1rem, 3rem);
}

.error-description {
    color: #4F4F4F;
    font-weight: 550;
    font-size: clamp(0.8rem, 0.25vw + 0.75rem, 3rem);
}
</style>