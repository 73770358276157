<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9FB;
  min-height: 100vh;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --h1-size: clamp(1.5rem, 3vw, 2.5rem);
  --h2-size: clamp(1.25rem, 2.5vw, 2rem);
  --h3-size: clamp(1rem, 2vw, 1.5rem);
}
</style>