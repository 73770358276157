import { authService } from "@/services/api/authService"
import { useAuthStore } from "@/stores/authStore";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const isMounted = ref(false)

export default () => {
    const router = useRouter();
    const route = useRoute();

    const authStore = useAuthStore();

    const { setUser, getToken, refreshJwt, setLoading } = authStore;
    const { user, loading, jwt, currentPage } = storeToRefs(authStore);

    const broadcastChannel = ref(new BroadcastChannel('auth'));
    const lang = ref();
    const flagSelected = ref();

    watch([lang, flagSelected], ([lang, flagSelected]) => {
        const { locale } = useI18n();

        locale.value = lang;
        localStorage.setItem('language', lang);
        localStorage.setItem('flag', flagSelected);
    }, { deep: true });

    const createLogin = async (body, redirect = true) => {
        clearSession();
        setLoading(true);
        const { data } = await authService.signIn(body);

        const { jwt, id_user, company_id, user_name, store_id, menu, tab, photo_url, email, department, profile } = data;

        sessionStorage.setItem('token', jwt);
        refreshJwt();
        setUser({
            userId: id_user,
            name: user_name,
            menu,
            companyId: company_id,
            storeId: store_id,
            tab,
            photoUrl: photo_url,
            email,
            department,
            profile
        });
        setLoading(false);

        localStorage.setItem('loggedIn', true);
        broadcastChannel.value.postMessage({ type: 'login', jwt });
        if(redirect) router.push('/');

        return data;
    }

    const refreshUserInfo = async (token) => {
        setLoading(true);
        const { data } = await authService.fetchUserByJwt(token);

        const { jwt, id_user, user_name, menu, company_id, store_id, tab, photo_url, email, department, profile } = data;

        sessionStorage.setItem('token', jwt);
        refreshJwt();

        setUser({
            userId: id_user,
            name: user_name,
            menu,
            companyId: company_id,
            storeId: store_id,
            tab,
            photoUrl: photo_url,
            email,
            department,
            profile
        });

        setLoading(false);

        document.cookie = `token=${jwt}`;

        return data;
    }

    const clearSession = () => {
        sessionStorage.removeItem("token");
        jwt.value = null;
        refreshJwt();
    }

    const logout = async (redirect = true, broadcast = true) => {
        clearSession();
        const token = getToken();

        try {
            await authService.logoutUser(user.value.userId, token);

            if (broadcast) {
                broadcastChannel.value.postMessage({ type: 'logout' });
            }
        } catch (e) {
            console.warn(e);
        }

        if (redirect) router.push("auth/login");
    }

    const changeStore = async (token, store, broadcast = true) => {
        const { data } = await authService.changeStore(token, store.store_id);

        const { jwt, id_user, company_id, user_name, menu, store_id, tab, photo_url, profile } = data;

        sessionStorage.setItem('token', jwt);
        refreshJwt();
        setUser({
            userId: id_user,
            name: user_name,
            companyId: company_id,
            storeId: store_id,
            menu,
            tab,
            photoUrl: photo_url,
            profile
        });

        if (broadcast) broadcastChannel.value.postMessage({ type: 'change_store', jwt, store: store_id });

        return data;
    }

    onMounted(() => {
        if(isMounted.value) return;
        const { redirect } = route.query
        isMounted.value = true;

        broadcastChannel.value.onmessage = async (event) => {
            const data = event.data;
            if (data.type == "login") {
                sessionStorage.setItem('token', data.jwt);
                refreshUserInfo(data.jwt);
            } else if (data.type == "logout") {
                logout(true, false);
                refreshJwt();
            } else if (data.type == "change_store") {
                await changeStore(data.jwt, data.store, false);
                await refreshUserInfo(data.jwt);
            } else if (data.type == "try_get_token") {
                if (sessionStorage.getItem('token')) {
                    broadcastChannel.value.postMessage({ type: 'auth_redirect', jwt: sessionStorage.getItem('token') });
                }
            } else if (data.type == "auth_redirect") {
                if (!sessionStorage.getItem('token')) {
                    await refreshUserInfo(data.jwt);

                    if (redirect) {
                        const redirectTo = atob(redirect);

                        router.push(redirectTo);
                    } else router.push('/');
                }
            }
        };
    });

    onUnmounted(() => {
        broadcastChannel.value.close();
    });

    return {
        createLogin,
        user,
        logout,
        refreshJwt,
        getToken,
        refreshUserInfo,
        clearSession,
        changeStore,
        loading,
        currentPage,
        broadcastChannel
    }
}