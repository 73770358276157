import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './18n'
import { createPinia } from 'pinia'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Icon } from '@iconify/vue'
import './assets/styles/bootstrap-grid.css';
import { MaskInput } from 'vue-3-mask';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Button from "primevue/button"
import { ConfirmDialog, FileUpload, Tree } from 'primevue'
import { ConfirmationService } from 'primevue'
import ToastService from 'primevue/toastservice'
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import ToggleSwitch from 'primevue/toggleswitch';
import Toast from 'primevue/toast';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import PrimeVueButton from 'primevue/button';
import Select from 'primevue/select';
import Skeleton from 'primevue/skeleton';
import Column from 'primevue/column';
import MultiSelect from 'primevue/multiselect';
import Tooltip from 'primevue/tooltip';
import PickList from 'primevue/picklist';
import ClickOutside from './directives/ClickOutside'
import { Switch, Transfer } from 'ant-design-vue';
import InputTextLine from './components/Forms/InputTextLine.vue'
import { FormField } from '@primevue/forms'
import AppError from '@/components/AppError.vue';
import Drawer from 'primevue/drawer';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import RadioButton from 'primevue/radiobutton'
import Editor from 'primevue/editor';
import InputNumber from 'primevue/inputnumber';
import ContextMenu from 'primevue/contextmenu';
import Textarea from 'primevue/textarea';
import TreeTable from 'primevue/treetable';

library.add(fas, far);

export const app = createApp(App);

app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            darkModeSelector: 'none',
        }
    },
});

const pinia = createPinia();

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('IconifyIcon', Icon);
app.component('MaskInput', MaskInput);
app.component('appButton', Button);
app.component('InputText', InputText);
app.component('DataTable', DataTable);
app.component('InputMask', InputMask);
app.component('ToggleSwitch', ToggleSwitch);
app.component('AppToast', Toast);
app.component('AppCheckbox', Checkbox);
app.component('AppMessage', Message);
app.component('AppDialog', Dialog);
app.component('AppButton', PrimeVueButton);
app.component('AppSelect', Select);
app.component('AppSkeleton', Skeleton);
app.component('AppColumn', Column);
app.component('AppMultiSelect', MultiSelect);
app.component('AppError', AppError);
app.component('ConfirmDialog', ConfirmDialog);
app.component('PickList', PickList);
app.component('a-transfer', Transfer);
app.component('a-tree', Tree);
app.component('a-switch', Switch);
app.component('InputTextLine', InputTextLine)
app.component('FormField', FormField);
app.component('AppDrawer', Drawer);
app.component('AppIconField', IconField);
app.component('AppInputIcon', InputIcon);
app.component('AppEditor', Editor);
app.component('AppTextarea', Textarea);
app.component('AppTree', Tree);
app.component('InputNumber', InputNumber);
app.component('ContextMenu', ContextMenu);
app.component('AppFileUpload', FileUpload);
app.component('RadioButton', RadioButton);
app.component('TreeTable', TreeTable);
app.use(router);
app.use(i18n);
app.use(pinia);
app.use(ConfirmationService);
app.use(ToastService);
app.directive('tooltip', Tooltip);
app.directive('click-outside', ClickOutside);
app.mount('#app');