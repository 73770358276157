import { createI18n } from "vue-i18n";

const messages = {
  "EN": {
    loading: "Loading...",
    select: "Select",
    success: "Success!",
    error: "Error!",
    content_copy: "Content copied",
    error_content_copy: "Error copying content",
    error_img_200: "Image size must be less than 400x400 pixels. This image has {width}{'x'}{height}",
    error_copy_enterprise_data: "Error copying enterprise data",
    copy_enterprise_data: "Click here to repeat enterprise",
    error_api_call: "Error calling API",
    success_api_call: "API call successful",
    authentication_error: "Authentication error",
    email_send_success: "E-mail sent successfully",
    email_send_error: "Error sending e-mail",
    orders: {
      tables: {
        tracking: {
          tracking: "TRACKING"
        },
        orders: {
          date_time: "Date/Time",
          order: "Order",
          erp_order: "ERP Order",
          invoice: "Invoice",
          status: "Status",
          customer_cpf: "CPF/Customer",
          amount: "Amount",
          payment_method: "Payment Method",
          channel_order_id: "Channel Order ID",
          actions: "Actions",
          app: "App"
        },
        personal_info: {
          personal_info: "PERSONAL INFO",
          delivery_address: "DELIVERY ADDRESS",
          name: "Name",
          cpf_cnpj: "CPF/CNPJ",
          rg_ie: "RG/IE",
          birth_date: "Birth Date",
          marital_status: "Marital Status",
          profession_industry: "Profession/Industry",
          receiver: "Receiver",
          email: "E-mail",
          gender: "Gender",
          mother_name: "Mother\"s Name",
          spouse: "Spouse",
          partner: "Partner",
          link: "Link",
          address: "Address",
          neighborhood: "Neighborhood",
          complement: "Complement",
          zip_code: "ZIP Code",
          city: "City",
          state: "State",
          phone: "Phone",
          mobile: "Mobile"
        },
        order_items: {
          order_items: "ORDER ITEMS",
          title: "Title",
          sku: "SKU",
          quantity: "Quantity",
          unit_price: "Unit Price",
          total_value: "Total Value",
          primary_feature: "Primary Feature",
          secondary_feature: "Secondary Feature",
          ean: "EAN",
          reference_code: "Reference Code",
          sub_total: "Sub Total",
          shipping_fee: "Shipping Fee (+)",
          discount: "Discount (-)",
          total: "Total"
        },
        billing_data: {
          billing_data: "BILLING DATA",
          billing_date: "Billing Date",
          branch_balance: "Branch Balance",
          branch_invoice: "Branch Invoice",
          erp_order: "ERP Order",
          invoice_number: "Invoice Number",
          invoice_key: "Invoice Key"
        },
        payment_data: {
          payment_data: "PAYMENT DATA",
          marketplace: "Marketplace",
          total_value: "Total Value",
          operation: "Operation",
          payment_method: "Payment Method",
          card_brand: "Card Brand",
          payment_type: "Payment Type",
          marketplace_authorization: "Marketplace Authorization",
          intermediary_id: "Intermediary ID",
          intermediary_cnpj: "Intermediary CNPJ",
          payment_institution_cnpj: "Payment Institution CNPJ",
          installments: "Installments",
          installment: "Installment",
          installment_value: "Installment Value",
          due_date: "Due Date",
          paid_value: "Paid Value",
          payment_date: "Payment Date",
          refund_value: "Refund Value",
          refund_date: "Refund Date",
          status: "Status"
        },
        carrier: {
          carrier: "CARRIER",
          transport_company: "Transport Company",
          delivery_deadline: "Delivery Deadline",
          deliver_by: "Deliver By",
          freight_simulation_data: "Freight Simulation Data",
          carrier_name: "Carrier Name",
          simulation: "Simulation",
          calculation_type: "Calculation Type",
          choice_code: "Choice Code",
          protocol: "Protocol"
        },
        comments: {
          comments: "COMMENTS",
          date_time: "Date/Time",
          user: "User",
          status: "Status",
          message: "Message"
        },
        history: {
          history: "HISTORY",
          date_time: "Date/Time",
          user: "User",
          description: "Description"
        }
      },
      modals: {
        edit_order: {
          order_edit: "Edit Order",
          current_order_status: "Current Order Status",
          new_order_status: "New Order Status",
          notes: "Notes",
          characters_used: "Characters Used:",
          files: "File(s)",
          upload_files: "Upload File(s)",
          cancel: "Cancel",
          save: "Save",
          status: {
            awaiting_approval: "Awaiting Approval",
            approved: "Approved",
            awaiting_billing: "Awaiting Billing",
            billing: "Billing",
            billed: "Billed",
            in_transit: "In Transit",
            delivered: "Delivered",
            return_requested: "Return Requested",
            returning: "Returning",
            returned: "Returned",
            canceled: "Canceled",
            marketplace_canceled: "Marketplace Canceled"
          },
          error_cannot_edit_order: "You cannot edit this order.",
        },
        tracking: {
          close: "Close",
          tracking: "Order tracking",
          date_time: "Date/Time",
          user: "User",
          status: "Status",
          description: "Description",
          system: "System"
        },
        history: {
          close: "Close",
          history: "Order history"
        },
        comments: {
          order_comments: "Order Comments",
          comment_message_placeholder: "Type your comment here...",
          send: "Send",
          date_time: "Date/Time",
          user: "User",
          status: "Status",
          description: "Description"
        }
      },
      details: {
        order_info: "ORDER INFORMATION",
        order_title: "Order No.",
        order_edit_button: "Update status",
        back: "Back",
        print: "Print",
        order_tracking: "Order Tracking",
        expand_collapse: "Expand/Collapse",
        personal_info: "Personal Information",
        order_items: "Order Items",
        billing_data: "Billing Data",
        payment_data: "Payment Data",
        comments: "Comments",
        carrier: "Carrier",
        history: "History",
        viewed_by: "Viewed on {date} by {user}",
      },
      orders_list: {
        title: "ORDER LIST",
        search: "Search orders...",
        filter: {
          form: {
            order_id: "Order",
            status: "Status",
            date_interval: "Date",
            customer_cpf: "CPF/Customer",
            payment_method: "Payment Method",
            order_erp: "ERP Order",
            channel_order_id: "Channel Order ID",
            invoice_number: "Invoice Number",
          }
        }
      }
    },
    subheader: {
      store: "Store",
      company: "Company",
      search: "Search companies...",
      search_company_error: "Error searching companies",
      search_store_error: "Error searching stores",
      no_records_found: "No records found",
    },
    header: {
      leave: "Leave",
      notification: "Notification",
      language: "Language",
      profile: "Profile",
      invite_user: "Invite User",
      change_password: "Change Password",
    },
    sidebar: {
      open_menu: "Open menu",
      close_menu: "Close menu",
      item_home: "Home",
      item_company: "Company",
      item_products: "Products",
      item_users: "Users",
      item_orders: "Orders",
      item_config: "Configuration",
      settings_log: "System Log",
      item_app: "Applications",
      item_help: "Help",
      item_categories: "Categories",
      item_publications: "Publications",
    },
    breadcrumb: {
      home: "Home",
      company: "Company",
      products: "Products",
      users: "Users",
      orders: "Orders",
      monitoring: "Monitoring",
      app: "Applications",
      help: "Help",
      company_tabs: {
        stores: "Stores",
        branches: "Branches"
      },
      users_profiles: "Profiles",
      register_product: "Registration",
      edit_product: "Edit",
      product_variations: "Registration",
      edit_product_variations: "Edit",
      product_attributes: "Registration",
      edit_product_attributes: "Edit",
      product_precification: "Registration",
      edit_product_precification: "Edit",
      product_stock: "Registration",
      edit_product_stock: "Edit",
      product_restrictions: "Registration",
      edit_product_restrictions: "Edit",
      settings: "Settings",
      settings_tabs: {
        logs: "System Logs",
      },
      order_detail: "Detail",
      apps: "Applications",
      product_categories: "Categories",
      product_categories_associate: "Categories",
      product_publications: "Publications",
      app_installation: "Applications",
    },
    company: {
      tabs: {
        company: "COMPANY",
        stores: "STORES",
        branches: "BRANCHES"
      },
      forms: {
        company: {
          corporate_name: "Corporate Name",
          corporate_name_required: "Corporate Name is required",
          trade_name: "Trade Name",
          trade_name_required: "Trade Name is required",
          sistem_name: "System Name",
          sistem_name_required: "System Name is required",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ is required",
          ie: "State Registration",
          postal_code: "Postal Code",
          postal_code_required: "Postal Code is required",
          address: "Address",
          address_required: "Address is required",
          number: "Number",
          number_required: "Number is required",
          neighborhood: "Neighborhood",
          neighborhood_required: "Neighborhood is required",
          address_complement: "Complement",
          city: "City",
          city_required: "City is required",
          state: "State",
          state_required: "State is required",
          phone: "Phone",
          country: "Country",
          country_required: "Country is required",
          currency: "Currency",
          select_image: "Select an image here!",
        },
        responsible: {
          name: "Name",
          department: "Department",
          email: "E-mail",
          phone: "Phone",
          cellphone: "Mobile Phone",
          name_required: "Name is required",
          email_required: "E-mail is required",
          email_invalid: "Invalid e-mail",
          department_required: "Department is required",
        }
      },
      tables: {
        managers: {
          name: "Name",
          department: "Department",
          email: "E-mail",
          phone: "Phone",
          mobile_phone: "Mobile Phone",
          id: "ID",
        },
        stores: {
          store_id: "ID",
          store_name: "Name",
          city: "City",
          state: "State",
          responsible: "Responsible",
          email: "E-mail",
          cellphone: "Cellphone",
          phone: "Phone",
          status: "Status",
          actions: "Actions",
        }
      },
      modals: {
        delete_responsible: {
          header: "Important!",
          message: "Do you really want to delete this responsible? By clicking the Yes button, the changes will be immediately made."
        },
        inactivate_company: {
          header: "Important!",
          message: "Do you really want to inactivate this company? By clicking the Yes button, the changes will be immediately made."
        },
        new_responsible: {
          header: "New Responsible",
          button_cancel: "Cancel",
          button_save: "Save",
          button_back: "Return"
        },
        edit_reponsible: {
          header: "Edit Responsible",
          button_cancel: "Cancel",
          button_save: "Save",
        }
      },
      button_yes: "Yes",
      button_no: "No",
      active: "active",
      inactive: "inactive",
      button_edit: "Edit",
      button_new_responsible: "New Responsible",
      general_information: "GENERAL INFORMATION",
      managers: "Managers",
      no_stores: "No stores registered",
      no_stores_description: "Register a new store by clicking the button above",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Store Name",
          responsible: "Responsible",
          email: "E-mail",
          phone: "Phone",
          city: "City",
          state: "State",
          cellphone: "Cellphone",
        },
      },
      modals: {
        new_store: {
          header: "New Store",
          button_cancel: "Cancel",
          button_save: "Save",
          store_name_required: "Store name is required",
          city_required: "City is required",
          state_required: "State is required",
          email_invalid: "Invalid e-mail",
        },
        edit_store: {
          header: "Edit Store",
        }
      },
      store_list: "STORE LIST",
      button_filter: "Filters",
      button_add_store: "Add Store",
      fetch_store_error: "Error searching stores",
      error_no_enterprise: "No enterprise selected",
    },
    company_branches: {
      tables: {
        branches: {
          id: "ID",
          name: "Name",
          tax_id: "CNPJ",
          type: "Branch Type",
          city: "City",
          state: "State",
          responsible: "Responsible",
          store: "Store",
          status: "Status",
          actions: "Actions",
          branch_types: {
            physical_store: "Physical Store",
            pickup_point: "Pickup Point",
            distribution_center: "Distribution Center",
            head_office: "Head Office",
            fulfillment_center: "Fulfillment Center",
          }
        }
      },
      modals: {
        new_branch: {
          header: "New Branch",
          button_cancel: "Cancel",
          button_save: "Save",
        },
        edit_branch: {
          header: "Edit Branch",
          button_cancel: "Cancel",
          button_save: "Save",
        },
        filter: {
          show: "",
          form: {
            active: "Active",
            inactive: "Inactive",
            name: "Name",
            tax_identifier: "Tax ID",
            city: "City",
            responsible: "Responsible",
            uf: "State",
            store: "Store",
            branch_type: "Branch Type",
          }
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ is required",
          branch_name: "Branch Name",
          branch_name_required: "Branch Name is required",
          postal_code: "Postal Code",
          postal_code_required: "Postal Code is required",
          address: "Address",
          address_required: "Address is required",
          address_complement: "Complement",
          city: "City",
          city_required: "City is required",
          state: "State",
          state_required: "State is required",
          state_placeholder: "Select a state",
          state_registration: "State Registration",
          responsable: "Responsable",
          store: "Stores",
          store_required: "Store is required",
          store_placeholder: "Select a store",
          stores_no_result: "No stores found",
          branch_type: "Branch Type",
          number: "Number",
          number_required: "Number is required",
          branch_type_placeholder: "Select a branch type",
          branch_type_required: "Branch Type is required",
          branch_types: {
            physical_store: "Physical Store",
            pickup_point: "Pickup Point",
            distribution_center: "Distribution Center",
            head_office: "Head Office",
            fulfillment_center: "Fulfillment Center",
          }
        }
      },
      error_search_branches: "Error searching branches",
      empty_branches: "No branches found",
      empty_branches_description: "Register a new branch by clicking the button above",
      filial_list: "BRANCH LIST",
      button_add_branch: "Add Branch",
      filters: "Filters"
    },
    users: {
      tables: {
        users: {
          id: "ID",
          name: "Name",
          email: "E-mail",
          role: "Role",
          department: "Department",
          status: "Status",
          actions: "Actions",
        }
      },
      modals: {
        newUser: {
          newUser: "New User",
          photo_size_recommend: "We recommend an image of 300x300 pixels",
          alt_img_preview: "Image preview",
        },
        editUser: {
          editUser: "Edit User",
          photo_size_recommend: "We recommend an image of 300x300 pixels",
          alt_img_preview: "Image preview",
        }
      },
      forms: {
        new_user: {
          name: "Name",
          email: "E-mail",
          profile: "Access Profile",
          stores: "Stores",
          realesed_stores: "Released Stores",
          department: "Department",
          name_required: "Name is required",
          email_required: "E-mail is required",
          email_invalid: "Invalid e-mail",
          stores_required: "Stores is required",
          department_required: "Department is required",
          profile_required: "Profile is required",
        }
      },
      no_users: "No users found",
      no_users_description: "Register a new user by clicking the button above",
      fetch_users_error: "Error fetching users",
      users_list: "USERS LIST",
      search: "Search users...",
      button_access_profiles: "Access Profiles",
      button_add_user: "Add User",
    },
    users_profiles: {
      profile_management: "PROFILE MANAGEMENT",
      button_duplicate_profile: "Duplicate Profile",
      button_add_profile: "Add Profile",
      available_options: "Available Options",
      options_released: "Options Released",
      profile_list: "PROFILE LIST",
      list_of_functionalities: "LIST OF FUNCTIONALITIES",
      clear_selection: "Clear selection"
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Password",
        no_account: "Don't have an account yet?",
        no_account_click_here: "Click here",
        forgot_my_password: "Forgot my password",
        log_in: "LOG IN"
      },
      forms: {
        login: {
          email_required: "E-mail is required",
          password_required: "Password is required",
          email_invalid: "E-mail is invalid"
        },
        reset_password: {
          email_required: "E-mail is required",
          email_invalid: "E-mail is invalid"
        },
        reset_token: {
          password_required: "Password is required",
          password_confirmation_required: "Password confirmation is required",
          password_confirmation_same: "Passwords must be the same",
          password_confirmation_invalid: "Invalid password confirmation",
          password_min: "Password must have at least 8 characters"
        },
      },
      reset_password: {
        title: "Reset Password",
        confirm: "RESET",
        return: " to back to login screen",
        click_here: "Click here"
      },
      register: {
        email: "Email",
        password: "Password",
        sign_up: "SIGN UP",
        department: "Department",
        name: "Name",
        confirm_password: "Confirm Password"
      },
      reset_token: {
        new_password: "New Password",
        confirm_new_password: "Confirm New Password",
      },
    },
    errors: {
      unexpected_error: {
        title: "UNEXPECTED ERROR!",
        description: "An unexpected error occurred in our system, please try again later."
      }
    },
    products: {
      modals: {
        confirm_cancel: "Do you really want to discard the changes made? By clicking Yes, the changes will be immediately discarded."
      },
      tabs: {
        general_data: "GENERAL DATA",
        variations: "VARIATIONS",
        attributes: "ATTRIBUTES",
        precification: "PRECIFICATION",
        stock: "STOCK",
        restrictions: "RESTRICTIONS",
      },
      products_list: {
        modals: {
          filter: {
            close_filter: "Close Filters",
            show: "Show:",
            active: "Active",
            inactive: "Inactive",
            form: {
              sku_group: "SKU Group",
              product_type: "Product Type",
              category: "Category",
              brand: "Brand",
              price: "Price",
              initial_amount: "Initial Value",
              final_amount: "Final Value",
              stock: "Stock",
              initial_quantity: "Initial Quantity",
              final_quantity: "Final Quantity",
              model: "Model",
            },
            apply_filters: "Apply Filters",
            clean_filters: "Clean Filters",
          },
          variations: {
            product_variations: "Product Variations",
            sku_group: "SKU Group",
            title: "Title",
            table: {
              ref_code: "Reference Code",
              ean: "Barcode (EAN)",
              primary_characteristic: "Primary Characteristic",
              secondary_characteristic: "Secondary Characteristic",
              active: "Active",
              inactive: "Inactive",
            },
            close: "Close",
            access_product: "Access Product",
          }
        },
        tables: {
          products: {
            sku_group: "SKU Group",
            image: "Image",
            title: "Title",
            product_type: "Product Type",
            kit_variation: "Kit with Variation",
            variation: "With Variation",
            simple: "Simple",
            category: "Category",
            brand: "Brand",
            model: "Model",
            standard_price: "Standard Price",
            total_stock: "Total Stock",
          },
        },
        title: "PRODUCT LIST",
        search: "Search products...",
        filters: "Filters",
        publications: "Publications",
        add_product: "Add Product",
        no_products: "No products found",
        no_products_description: "Register a new product by clicking the button above",
        no_products_selected: "No products selected",
      },
      details: {
        no_categories: "No categories registered",
        select_category: "Select a category",
        select_gender: "Select a gender",
        select_manufactorer: "Select a manufacturer",
        no_category: "No category found",
        product_details: "GENERAL PRODUCT INFORMATION",
        history: "History",
        import_product: "Import Product",
        select_image: "Add images in the Variations tab",
        sku_group: "SKU Group",
        product_status: "Product Status",
        used_characteres: "Used characters",
        months: "Months",
        month: "Month",
        forms: {
          product: {
            title: "Title",
            title_required: "Title is required",
            title_helper: "(Title send to marketplace)",
            short_title: "Short Title",
            category: "Category",
            brand: "Brand",
            model: "Model",
            nbm: "NBM/NCM",
            gender: "Gender",
            volumes: "Volumes",
            manufacturer: "Manufacturer",
            warranty_period: "Warranty Period",
            months: "(Months)",
            package_information: "PACKAGE INFORMATION",
            weight: "Weight",
            height: "Height",
            width: "Width",
            depth: "Depth",
            description: "DESCRIPTION",
            cubage: "Cubage",
          }
        },
        modals: {
          import_product: {
            import_product: "Import Product",
            import_button: "Import",
          },
          history: {
            product_history: "Product History",
            close: "Close",
          },
          commentaries: {
            product_commentaries: "Product Commentaries",
            digit_here: "Digit here",
            send: "Send",
          }
        },
        editor: {
          bold: "Bold",
          italic: "Italic",
          underline: "Underline",
          help: "The maximum character limits allowed by each marketplace will be published. Descriptions exceeding these limits will be truncated!"
        }
      },
      variations: {
        no_variations: "No variations found",
        no_variations_descriptions: "Register a new variation by clicking the button above",
        product_variations: "PRODUCT VARIATIONS",
        characteristics_types: "Characteristics Types",
        add_variation: "Add Variation",
        main_variation: "Main Variation",
        variation: "Variation",
        main_image: "Main Image",
        photos_inserted: "Photos inserted",
        upload_photos: "Upload photos",
        primary_characteristic: "Primary Characteristic",
        secondary_characteristic: "Secondary Characteristic",
        select: "Select...",
        ref_code: "Reference Code",
        bar_code: "Barcode",
        main_variation_tooltip: "This will be the main variation displayed in the product listing.",
        variation_tooltip: "This will be the variation displayed in the product listing.",
        set_main_variation_header_tooltip: "Important!",
        set_main_variation_message_tooltip: "This will be the main variation displayed in the product listing.",
        modals: {
          characteristics: {
            characteristics_type: "CHARACTERISTICS TYPE",
            add_characteristics_type: "Add Characteristics Type",
            name: "Name",
            value: "Value",
            add_feature: "Add Feature"
          },
          product_association: {
            header: "Important!",
            message: "This variation is linked to an order and cannot be deleted.",
          },
          duplicate_variation_gtin: {
            header: "Important!",
            title: "A variation with this EAN already exists.",
            message: "Make sure you have entered it correctly or deactivate the other existing variation with the same EAN."
          },
          main_variation: {
            header: "Important!",
            message: "There is no active variation to be the main one.",
          }
        }
      },
      precification: {
        sku_group: "SKU Group",
        price_of: "Price From",
        price_of_tooltip: "Gross price of the product, without discounts, additions, and promotions!",
        discount: "Discount",
        discount_tooltip: "Discount applied to the gross value of the product. Discounts, surcharges, and promotions carried out in the Campaigns area are not included.",
        standard_price: "Standard Price",
        standard_price_tooltip: "Net price of the product, excluding discounts, surcharges, and promotions carried out in the Campaigns area.",
        sku_standard_price_tooltip: "Price of the product variation excluding current discounts, surcharges, and promotions.",
        sku: "SKU",
        reference_code: "Reference Code",
        primary_feature: "Primary Feature",
        secondary_feature: "Secondary Feature",
        voltage: "Voltage",
        barcode: "Barcode",
        standard_price_per_sku: "Standard Price per SKU",
        prices_practiced_in_marketplaces_title: "Prices practiced in marketplaces",
        prices_practiced_in_marketplace_tooltip: "Prices applied in marketplaces already including current discounts, surcharges, and promotions.",
        prices_practiced_in_marketplaces: {
          channel: "Channel",
          price: "Price",
          note: "Note"
        }
      },
      attributes: {
        attributes_by_marketplace: "List of Marketplaces",
        category: "Category",
        attribute: "Attribute",
        value: "Value",
        attribute_name_already_exists: "The attribute name is already being used.",
        product_attributes: "Product Attributes",
        add_attribute: "Add Attribute",
        search: "Search"
      },
      stock: {
        available_stock_by_sku_and_branch: "AVAILABLE STOCK BY SKU AND BRANCH",
        sku_group: "SKU Group",
        sku: "SKU",
        reference_code: "Reference Code",
        primary_characteristic: "Primary Characteristic",
        secondary_characteristic: "Secondary Characteristic",
        gtin: "Barcode",
        available_total_sku_group: "Total Available Stock SKU Group",
        minimum_stock_sku_group: "Minimum Stock SKU Group",
        available_total: "Available Total",
        minimum_stock: "Minimum Stock",
        restrictions_by_marketplace: "Restrictions by Marketplace",
        total_available_tooltip: 'Sum of the value entered in the "Total Available" field for all existing active variations.',
        default_minimum_stock_tooltip: 'This value will be used if the variation does not have a "Minimum Stock" specified.',
        available_stock_calculation_tooltip: 'Calculation of the "Available Stock" field minus the values of "Minimum Stock" and "Safety Stock" from all active branches of the SKU.',
        marketplace_sales_cutoff_tooltip: 'SKU stock quantity that will be considered to trigger sales cut-off in the marketplace.',
        stock_help_tooltip: 'Click here to learn more about stock options.',
        safety_stock_tooltip: 'Stock quantity that will be reserved for security purposes during integration "delays".',
        crossdocking_lead_time_tooltip: 'Additional lead time for sales in the Crossdocking format.',
        available_stock_marketplace_tooltip: 'The value in the "Available Stock" field will be disregarded for inactive marketplaces in the given variation.',
        branch: {
          available_stock: "Available Stock",
          stock_type: "Stock Type",
          safety_stock: "Safety Stock",
          additional_lead_time: "Additional Dispatch Time",
          select: "Select..."
        },
        stock_types: {
          automatic: "Automatic",
          fixed: "Fixed",
          reducing: "Reducing"
        },
        modals: {
          help_stock: {
            help_stock: "Help",
            subtitle: "TYPES OF STOCK",
            stock_automatic_highlight: "Automatic – API Interaction:",
            stock_automatic_description: "In this option, the “Available Stock” will be updated via API.",
            stock_automatic_observation: "The crossdocking deadline “Additional Time” does not apply to this option.",
            observation: "Observation:",
            observations: "Observations:",
            stock_fix_highlight: "Fixed Stock:",
            stock_fix_description: "In this option, the “Available Stock” will be manually entered and will not change if sales occur.",
            stock_fix_observation: "The value entered in the “Additional Time” field for delivery will be added to the transportation time.",
            reducing_stock: "Reducing Stock Item:",
            reducing_stock_description: "In this option, the “Available Stock” will be manually entered and will decrease with sales.",
            observation_1: "If there is a pending order cancellation, the stock will not increase automatically.",
            observation_2: "The value entered in the “Additional Deadline” field for delivery will be added to the shipping time."
          },
          filter: {
            show: "Show:",
            variation_status: "Variation Status",
            branch_status: "Branch Status",
            primary_characteristic: "Primary Characteristic",
            secondary_characteristic: "Secondary Characteristic",
            sku: "SKU",
            reference_code: "Reference Code",
            ean: "Barcode",
            active: "Active",
            inactive: "Inactive",
          }
        }
      },
      restrictions: {
        ref_code: "Reference Code",
        primary_characteristic: "Primary Feature",
        secondary_characteristic: "Secondary Characteristic",
        barcode: "Barcode",
        marketplace_restrictions: "Sales Restrictions by Marketplace",
        sales_restrictions_by_marketplace_tooltip: "This section allows you to configure sales restrictions on specific marketplaces.",
      },
      categories: {
        modals: {
          delete_category: {
            header: "Important!",
            message: "Are you sure you want to delete this category/subcategory? Clicking Yes will not apply the changes yet.",
          }
        },
        cannot_exclude_category_product: "It is not possible to exclude a category that is associated with a product.",
        cannot_exclude_category_last: "You cannot delete it because it is the only child of this category.",
        cannot_exclude_category_unique_root: "You cannot delete it because it is the only root category.",
        title: "CATEGORY MANAGEMENT",
        search: "Search categories..",
        associate_category: "Associate Category",
        add_category: "Add Category",
        category_registered: "CATEGORIES REGISTERED IN LINKHUB",
        my_categories: "My Categories",
        product_quantity: "Quantity of Products",
        actions: "Actions",
        products: "Product(s)",
      },
      associate_category: {
        modals: {
          dissasociateCategory: {
            header: "Important!",
            message: "Do you really want to delete the selected association? Clicking the Yes button will immediately apply the changes."
          },
          associateCategory: {
            associateMktpCategory: "Associate Marketplace Category"
          }
        },
        title: "ASSOCIATE CATEGORIES",
        search: "Search categories...",
        associate_with_mktp: "ASSOCIATE MY CATEGORIES WITH THOSE OF THE MARKETPLACES",
        associated_categories: "Associated Categories",
        no_associated_categories: "No associated categories",
        categories: "Categories",
        not_associated: "Not associated",
        associated: "Associated",
        product_quantity: "Quantity of Products",
        actions: "Actions",
        products: "Product(s)",
      },
      publication_list: {
        modals: {
          filter: {
            sku_group: "SKU Group",
            sku: "SKU",
            practiced_price: "Practiced Price",
            marketplaces: "Marketplace(s)",
            initial_price: "Initial Price",
            final_price: "Final Price",
            stock: "Stock",
            status: "Status"
          },
          history: {
            header: "Product Publication History",
            title: "Title",
            marketplace: "Marketplace",
            sku_group: "SKU Group",
            sku: "SKU",
            date_time: "Date/Time",
            user: "User",
            status: "Status",
            description: "Description"
          }
        },
        status: {
          not_published: "Not Published",
          published: "Published",
          in_processing: "In Processing",
        },
        title: "PRODUCTS TO PUBLISH",
        title_tooltip: "It is necessary to fill in the fields Photo, Title, Category, Brand, Model, Description, Price, and Stock for the product to be listed in this area.",
        confirm_publish_products: "Do you really want to publish the selected products? By clicking the Yes button, the publications will be immediately made.",
        confirm_publish_products_header: "Important!"
      }
    },
    settings: {
      logs: {
        system_logs: "SYSTEM LOGS",
        search_input: "Search logs...",
        module: "Module",
        period: "Period",
        no_logs: "No logs found",
        no_logs_description: "No logs found for the module selected",
      }
    },
    apps: {
      list: {
        apps_list: "APPLICATIONS LIST",
        develop_app: "Create App",
        fast_filter: {
          title: "Fast Filter",
          all: "All",
          installed_apps: "Installed Apps",
          e_commerce: "Marketplace",
          erps: "ERPs",
          transport: "Transport",
          several: "Several",
        },
        certified_app: "CERTIFIED APP",
        not_certified_app: "NOT CERTIFIED APP",
        evaluations: "Evaluations",
        configuration: "Configuration",
        usability: "Usability",
        cost: "Cost",
        app_cost: "App Cost",
        free: "Free",
        paid_app: "Paid App",
        developed_by: "Developed by",
        installed: "Installed",
        get_app: "Get App",
        menu: {
          evaluate: "Evaluate",
          certify: "Certify",
          edit: "Edit",
          uninstall: "Uninstall",
        },
        confirm_desinstall_app_header: "Important!",
        confirm_desinstall_app_message: "Do you really want to uninstall this app? By clicking Yes, the changes will be immediately made.",
      },
      stepper: {
        step1: "Authentication",
        step2: "Configuration",
        step3: "Finish",
      }
    }
  },
  "pt_BR": {
    loading: "Carregando...",
    select: "Selecione",
    success: "Sucesso!",
    error: "Erro!",
    content_copy: "Conteúdo copiado",
    error_content_copy: "Erro ao copiar conteúdo",
    error_img_200: "Imagem deve ter menos de 200x200 pixels",
    error_copy_enterprise_data: "Erro ao copiar dados da empresa",
    copy_enterprise_data: "Clique aqui para repetir os dados da empresa",
    error_api_call: "Erro ao chamar API",
    success_api_call: "Chamada API bem-sucedida",
    authentication_error: "Erro de autenticação",
    email_send_success: "E-mail enviado com sucesso",
    email_send_error: "Erro ao enviar e-mail",
    orders: {
      tables: {
        tracking: {
          tracking: "ACOMPANHAMENTO DO PEDIDO"
        },
        orders: {
          date_time: "Data/Hora",
          order: "Pedido",
          erp_order: "Pedido ERP",
          invoice: "Nota Fiscal",
          status: "Situação",
          customer_cpf: "CPF/Cliente",
          amount: "Valor",
          payment_method: "Método de Pagamento",
          channel_order_id: "ID Pedido Canal",
          actions: "Ações",
          app: "Aplicativo"
        },
        personal_info: {
          personal_info: "INFORMAÇÕES PESSOAIS",
          name: "Nome",
          cpf_cnpj: "CPF/CNPJ",
          delivery_address: "ENDEREÇO DE ENTREGA",
          rg_ie: "RG/IE",
          birth_date: "Data de Nascimento",
          marital_status: "Estado Civil",
          profession_industry: "Profissão/Ramo",
          receiver: "Recebedor",
          email: "E-mail",
          gender: "Sexo",
          mother_name: "Nome da Mãe",
          spouse: "Cônjuge",
          partner: "Parceiro",
          link: "Link",
          address: "Endereço",
          neighborhood: "Bairro",
          complement: "Complemento",
          zip_code: "CEP",
          city: "Cidade",
          state: "Estado",
          phone: "Telefone",
          mobile: "Celular"
        },
        order_items: {
          order_items: "ITENS DO PEDIDO",
          title: "Título",
          sku: "SKU",
          quantity: "Quantidade",
          unit_price: "Valor Unitário",
          total_value: "Valor Total",
          primary_feature: "Característica Primária",
          secondary_feature: "Característica Secundária",
          ean: "EAN",
          reference_code: "Código de Referência",
          sub_total: "Sub Total",
          shipping_fee: "Frete (+)",
          discount: "Desconto (-)",
          total: "Total"
        },
        billing_data: {
          billing_data: "DADOS DE FATURAMENTO",
          billing_date: "Data de faturamento",
          branch_balance: "Filial Saldo",
          branch_invoice: "Filial Fatura",
          erp_order: "Pedido ERP",
          invoice_number: "Núm. Nota Fiscal",
          invoice_key: "Chave da Nota Fiscal"
        },
        payment_data: {
          payment_data: "DADOS DO PAGAMENTO",
          marketplace: "Marketplace",
          total_value: "Valor Total",
          operation: "Operação",
          payment_method: "Meio de Pagamento",
          card_brand: "Bandeira do Cartão",
          payment_type: "Forma de Pagamento",
          marketplace_authorization: "Autorização Marketplace",
          intermediary_id: "Identificador do Intermediário",
          intermediary_cnpj: "CNPJ Intermediário Transação",
          payment_institution_cnpj: "CNPJ Instituição Pagamento",
          installments: "Parcelas",
          installment: "Parcela",
          installment_value: "Valor Parcela",
          due_date: "Data Vencimento",
          paid_value: "Valor Pago",
          payment_date: "Data Pagamento",
          refund_value: "Valor Estorno",
          refund_date: "Data Estorno",
          status: "Situação"
        },
        carrier: {
          carrier: "TRANSPORTADORA",
          transport_company: "Empresa de Transporte",
          delivery_deadline: "Prazo de Entrega",
          deliver_by: "Entregar até o dia",
          freight_simulation_data: "Dados de Retorno da Simulação de Frete",
          carrier_name: "Transportadora",
          simulation: "Simulação",
          calculation_type: "Tipo de Cálculo",
          choice_code: "Código de Escolha",
          protocol: "Protocolo"
        },
        comments: {
          comments: "COMENTÁRIOS",
          date_time: "Data/Hora",
          user: "Usuário",
          status: "Status",
          message: "Mensagem"
        },
        history: {
          history: "HISTÓRICO",
          date_time: "Data/Hora",
          user: "Usuário",
          description: "Descrição"
        }
      },
      modals: {
        edit_order: {
          order_edit: "Edição do Pedido",
          current_order_status: "Situação Atual do Pedido",
          new_order_status: "Nova Situação do Pedido",
          notes: "Observações",
          characters_used: "Caracteres utilizados:",
          files: "Arquivo(s)",
          upload_files: "Carregar Arquivo(s)",
          cancel: "Cancelar",
          save: "Salvar",
          status: {
            awaiting_approval: "Aguardando Aprovação",
            approved: "Aprovado",
            awaiting_billing: "Aguardando Faturamento",
            billing: "Faturando",
            billed: "Faturado",
            in_transit: "Em Trânsito",
            delivered: "Entregue",
            return_requested: "Devolução Solicitada",
            returning: "Devolvendo",
            returned: "Devolvido",
            canceled: "Cancelado",
            marketplace_canceled: "Cancelado pelo Marketplace"
          },
          error_cannot_edit_order: "Não é possível editar esse pedido.",
        },
        tracking: {
          close: "Fechar",
          tracking: "Acompanhamento do pedido",
          date_time: "Data/Hora",
          user: "Usuário",
          status: "Situação",
          description: "Descrição",
          system: "Sistema"
        },
        history: {
          history: "Histórico do pedido"
        },
        comments: {
          order_comments: "Comentários do Pedido",
          comment_message_placeholder: "Digite seu comentário aqui...",
          send: "Enviar"
        },
      },
      details: {
        order_info: "INFORMAÇÕES DO PEDIDO",
        order_title: "PEDIDO N°",
        order_edit_button: "Atualizar status",
        back: "Voltar",
        print: "Imprimir",
        order_tracking: "Rastreamento do Pedido",
        expand_collapse: "Expandir/Colapsar",
        personal_info: "Informações Pessoais",
        order_items: "Itens do Pedido",
        billing_data: "Dados de Faturamento",
        payment_data: "Dados de Pagamento",
        comments: "Comentários",
        carrier: "Transportadora",
        history: "Histórico",
        viewed_by: "Visualizado dia {date} por {user}",
      },
      orders_list: {
        title: "LISTA DE PEDIDOS",
        search: "Pesquisar pedidos...",
        filter: {
          form: {
            order_id: "Pedido",
            status: "Situação",
            date_interval: "Data",
            customer_cpf: "CPF/Cliente",
            payment_method: "Método de Pagamento",
            order_erp: "Pedido ERP",
            channel_order_id: "ID do Pedido no Canal",
            invoice_number: "Núm. Nota Fiscal",
          }
        }
      }
    },
    subheader: {
      store: "Loja",
      company: "Empresa",
      search: "Pesquisar empresas...",
      search_company_error: "Erro ao buscar empresas",
      search_store_error: "Erro ao buscar lojas",
      no_records_found: "Nenhum registro encontrado",
    },
    header: {
      leave: "Sair",
      notification: "Notificação",
      language: "Idioma",
      profile: "Perfil",
      invite_user: "Convidar Usuário",
      change_password: "Alterar Senha",
    },
    sidebar: {
      open_menu: "Abrir menu",
      close_menu: "Fechar menu",
      item_home: "Home",
      item_company: "Empresa",
      item_products: "Produtos",
      item_users: "Usuários",
      item_orders: "Pedidos",
      item_config: "Configurações",
      settings_log: "Log de Sistema",
      item_app: "Aplicativos",
      item_help: "Ajuda",
      item_categories: "Categorias",
      item_publications: "Publicações",
    },
    breadcrumb: {
      home: "Home",
      company: "Empresa",
      products: "Produtos",
      users: "Usuários",
      orders: "Pedidos",
      monitoring: "Monitoramento",
      app: "Aplicativos",
      help: "Ajuda",
      company_tabs: {
        stores: "Lojas",
        branches: "Filiais"
      },
      users_profiles: "Perfis",
      register_product: "Cadastro",
      edit_product: "Editar",
      product_variations: "Cadastro",
      edit_product_variations: "Editar",
      product_attributes: "Cadastro",
      edit_product_attributes: "Editar",
      product_precification: "Cadastro",
      edit_product_precification: "Editar",
      product_stock: "Cadastro",
      edit_product_stock: "Editar",
      product_restrictions: "Cadastro",
      edit_product_restrictions: "Editar",
      settings: "Configurações",
      settings_tabs: {
        logs: "Logs de Sistema",
      },
      order_detail: "Detalhe",
      apps: "Aplicativos",
      product_categories: "Categorias",
      product_categories_associate: "Categorias",
      product_publications: "Publicações",
      app_installation: "Aplicativos"
    },
    company: {
      tabs: {
        company: "EMPRESA",
        stores: "LOJAS",
        branches: "FILIAIS",
      },
      forms: {
        company: {
          corporate_name: "Razão Social",
          corporate_name_required: "Razão Social é obrigatório",
          trade_name: "Nome Fantasia",
          trade_name_required: "Nome Fantasia é obrigatório",
          sistem_name: "Nome no Sistema",
          sistem_name_required: "Nome no Sistema é obrigatório",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ é obrigatório",
          ie: "Inscrição Estadual",
          postal_code: "CEP",
          postal_code_required: "CEP é obrigatório",
          address: "Endereço",
          address_required: "Endereço é obrigatório",
          number: "Número",
          number_required: "Número é obrigatório",
          neighborhood: "Bairro",
          neighborhood_required: "Bairro é obrigatório",
          address_complement: "Complemento",
          city: "Cidade",
          city_required: "Cidade é obrigatório",
          state: "Estado",
          state_required: "Estado é obrigatório",
          phone: "Telefone",
          country: "País",
          country_required: "País é obrigatório",
          currency: "Moeda",
          select_image: "Selecione uma imagem aqui!",
        },
        responsible: {
          name: "Nome",
          department: "Departamento",
          email: "E-mail",
          phone: "Telefone",
          cellphone: "Celular",
          name_required: "Nome é obrigatório",
          email_required: "E-mail é obrigatório",
          department_required: "Departamento é obrigatório",
        }
      },
      tables: {
        managers: {
          name: "Nome",
          department: "Departamento",
          email: "E-mail",
          phone: "Telefone",
          mobile_phone: "Celular",
          id: "Código",
        },
        stores: {
          store_id: "Cód.",
          store_name: "Nome",
          city: "Cidade",
          state: "Estado",
          responsible: "Responsável",
          email: "E-mail",
          cellphone: "Celular",
          phone: "Telefone",
          status: "Status",
          actions: "Ações",
        }
      },
      modals: {
        delete_responsible: {
          header: "Importante!",
          message: "Deseja realmente excluir esse responsável? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
        },
        inactivate_company: {
          header: "Importante!",
          message: "Deseja realmente inativar essa empresa? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
        },
        new_responsible: {
          header: "Novo Responsável",
          button_cancel: "Cancelar",
          button_save: "Salvar",
          button_back: "Voltar"
        },
        edit_reponsible: {
          header: "Editar Responsável",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        }
      },
      button_yes: "Sim",
      button_no: "Não",
      active: "ativo",
      inactive: "inativo",
      button_edit: "Editar",
      button_new_responsible: "Novo Responsável",
      general_information: "DADOS GERAIS DA EMPRESA",
      managers: "Responsáveis",
      no_stores: "Nenhuma loja cadastrada",
      no_stores_description: "Cadastre uma nova loja clicando no botão acima",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Nome da Loja",
          responsible: "Responsável",
          email: "E-mail",
          phone: "Telefone",
          city: "Cidade",
          state: "Estado",
          cellphone: "Celular",
        }
      },
      modals: {
        new_store: {
          header: "Nova Loja",
          button_cancel: "Cancelar",
          button_save: "Salvar",
          store_name_required: "Nome da loja é obrigatório",
          city_required: "Cidade é obrigatório",
          state_required: "Estado é obrigatório",
          email_invalid: "E-mail inválido",
        },
        edit_store: {
          header: "Editar Loja",
        }
      },
      store_list: "LISTAGEM DE LOJAS",
      button_filter: "Filtros",
      button_add_store: "Adicionar Loja",
      fetch_store_error: "Erro ao buscar lojas",
      error_no_enterprise: "Nenhuma empresa selecionada",
    },
    company_branches: {
      tables: {
        branches: {
          id: "Código",
          name: "Nome",
          tax_id: "CNPJ",
          type: "Tipo de filial",
          city: "Cidade",
          state: "UF",
          responsible: "Responsável",
          store: "Loja",
          status: "Status",
          actions: "Ações",
          branch_types: {
            physical_store: "Loja Física",
            pickup_point: "Ponto de Retirada",
            distribution_center: "Centro de Distribuição",
            head_office: "Matriz",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      modals: {
        new_branch: {
          header: "Nova Filial",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        },
        edit_branch: {
          header: "Editar Filial",
          button_cancel: "Cancelar",
          button_save: "Salvar",
        },
        filter: {
          show: "",
          form: {
            active: "Ativo",
            inactive: "Inativo",
            name: "Nome",
            tax_identifier: "CNPJ",
            city: "Cidade",
            responsible: "Responsável",
            uf: "UF",
            store: "Loja",
            branch_type: "Tipo de Filial",
          }
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ é obrigatório",
          branch_name: "Nome da Filial",
          branch_name_required: "Nome da Filial é obrigatório",
          postal_code: "CEP",
          postal_code_required: "CEP é obrigatório",
          address: "Endereço",
          address_required: "Endereço é obrigatório",
          address_complement: "Complemento",
          city: "Cidade",
          city_required: "Cidade é obrigatório",
          state: "Estado",
          state_required: "Estado é obrigatório",
          state_placeholder: "Selecione um estado",
          state_registration: "Inscrição Estadual",
          responsable: "Responsável",
          store: "Lojas",
          store_required: "Loja é obrigatório",
          store_placeholder: "Selecione uma loja",
          stores_no_result: "Nenhuma loja encontrada",
          branch_type: "Tipo de Filial",
          number: "Número",
          number_required: "Número é obrigatório",
          branch_type_placeholder: "Selecione um tipo de filial",
          branch_type_required: "Tipo de Filial é obrigatório",
          branch_types: {
            physical_store: "Loja Física",
            pickup_point: "Ponto de Retirada",
            distribution_center: "Centro de Distribuição",
            head_office: "Matriz",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      error_search_branches: "Erro ao buscar filiais",
      empty_branches: "Nenhuma filial encontrada",
      empty_branches_description: "Cadastre uma nova filial clicando no botão acima",
      filial_list: "LISTAGEM DE FILIAIS",
      button_add_branch: "Adicionar Filial",
      filters: "Filtros"
    },
    users: {
      tables: {
        users: {
          id: "ID",
          name: "Nome",
          email: "E-mail",
          role: "Perfil",
          department: "Departamento",
          status: "Status",
          actions: "Ações",
        }
      },
      modals: {
        newUser: {
          newUser: "Novo Usuário",
          photo_size_recommend: "Recomendamos uma imagem de 300x300 pixels",
          alt_img_preview: "Pré-visualização da imagem",
        },
        editUser: {
          editUser: "Editar Usuário",
          photo_size_recommend: "Recomendamos uma imagem de 300x300 pixels",
          alt_img_preview: "Pré-visualização da imagem",
        }
      },
      forms: {
        new_user: {
          name: "Nome",
          email: "E-mail",
          profile: "Perfil de Acesso",
          stores: "Lojas",
          realesed_stores: "Lojas Liberadas",
          department: "Departamento",
          name_required: "Nome é obrigatório",
          email_required: "E-mail é obrigatório",
          email_invalid: "E-mail inválido",
          stores_required: "Lojas é obrigatório",
          department_required: "Departamento é obrigatório",
          profile_required: "Perfil é obrigatório",
        }
      },
      no_users: "Nenhum usuário encontrado",
      no_users_description: "Cadastre um novo usuário clicando no botão acima",
      fetch_users_error: "Erro ao buscar usuários",
      users_list: "LISTAGEM DE USUÁRIOS",
      search: "Pesquisar usuários...",
      button_access_profiles: "Perfis de Acesso",
      button_add_user: "Adicionar Usuário",
    },
    users_profiles: {
      profile_management: "GESTÃO DE PERFIS",
      button_duplicate_profile: "Duplicar Perfil",
      button_add_profile: "Adicionar Perfil",
      available_options: "Opções Disponíveis",
      options_released: "Opções Liberadas",
      clear_selection: "Limpar Marcações",
      profile_list: "LISTA DE PERFIS",
      list_of_functionalities: "LISTA DE FUNCIONALIDADES",
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Senha",
        no_account: "Ainda não possui uma conta?",
        no_account_click_here: "Clique aqui",
        forgot_my_password: "Esqueci minha senha",
        log_in: "INICIAR SESSÃO",
      },
      register: {
        email: "E-mail",
        password: "Senha",
        sign_up: "INICIAR SESSÃO",
        department: "Departamento",
        name: "Nome",
        confirm_password: "Confirmar senha"
      },
      forms: {
        login: {
          email_required: "E-mail é obrigatório",
          password_required: "Senha é obrigatória",
          email_invalid: "E-mail inválido"
        },
        reset_password: {
          email_required: "E-mail é obrigatório",
          email_invalid: "E-mail inválido"
        },
        reset_token: {
          password_required: "Senha é obrigatória",
          password_confirmation_required: "Confirmação de senha é obrigatória",
          password_confirmation_same: "As senhas devem ser iguais",
          password_confirmation_invalid: "Confirmação de senha inválida",
          password_min: "Senha deve ter no mínimo 8 caracteres"
        }
      },
      reset_password: {
        title: "Reset Password",
        confirm: "REDEFINIR",
        return: " para voltar a tela de login.",
        click_here: "Clique aqui"
      },
      reset_token: {
        new_password: "Nova Senha",
        confirm_new_password: "Confirmar Nova Senha",
      },
    },
    errors: {
      unexpected_error: {
        title: "ERRO INSPERADO!",
        description: "Ocorreu um erro inesperado em nosso sistema, por favor tente mais tarde."
      }
    },
    products: {
      modals: {
        confirm_cancel: "Deseja realmente descartar as alterações realizadas? Ao clicar no botão Sim, as alterações serão imediatamente descartadas."
      },
      tabs: {
        general_data: "DADOS GERAIS",
        variations: "VARIAÇÕES",
        attributes: "ATRIBUTOS",
        precification: "PRECIFICAÇÃO",
        stock: "ESTOQUE",
        restrictions: "RESTRIÇÕES",
      },
      products_list: {
        modals: {
          filter: {
            close_filter: "Recolher Filtros",
            show: "Exibir:",
            active: "Ativos",
            inactive: "Inativos",
            form: {
              sku_group: "SKU Grupo",
              product_type: "Tipo de Produto",
              category: "Categoria",
              brand: "Marca",
              price: "Preço",
              initial_amount: "Valor Inicial",
              final_amount: "Valor Final",
              stock: "Estoque",
              initial_quantity: "Quantidade Inicial",
              final_quantity: "Quantidade Final",
              model: "Modelo",
            },
            apply_filters: "Aplicar Filtros",
            clean_filters: "Limpar Filtros",
          },
          variations: {
            product_variations: "Variações do Produto",
            sku_group: "SKU Grupo",
            title: "Título",
            table: {
              ref_code: "Código de Referência",
              ean: "Código de Barras (EAN)",
              primary_characteristic: "Característica Primária",
              secondary_characteristic: "Característica Secundária",
              active: "Ativo",
              inactive: "Inativo",
            },
            close: "Fechar",
            access_product: "Acessar Produto",
          }
        },
        tables: {
          products: {
            sku_group: "SKU Grupo",
            image: "Imagem",
            title: "Título",
            product_type: "Tipo de Produto",
            kit_variation: "Kit com Variação",
            variation: "Com Variação",
            simple: "Simples",
            category: "Categoria",
            brand: "Marca",
            model: "Modelo",
            standard_price: "Preço Padrão",
            total_stock: "Estoque Total",
          }
        },
        title: "LISTAGEM DE PRODUTOS",
        search: "Pesquisar produtos...",
        filters: "Filtros",
        publications: "Publicações",
        add_product: "Adicionar Produto",
        no_products: "Nenhum produto encontrado",
        no_products_description: "Cadastre um novo produto clicando no botão acima",
        no_products_selected: "Nenhum produto selecionado"
      },
      details: {
        no_categories: "Nenhuma categoria cadastrada",
        select_category: "Selecione uma categoria",
        select_gender: "Selecione o gênero",
        select_manufactorer: "Selecione o fabricante",
        no_category: "Nenhuma categoria encontrada",
        product_details: "DADOS GERAIS DO PRODUTO",
        history: "Histórico",
        import_product: "Importar Produto",
        select_image: "Adicione imagens na aba Variações",
        sku_group: "SKU Grupo",
        product_status: "Status do Produto",
        used_characteres: "Caracteres utilizados",
        months: "Meses",
        month: "Mês",
        forms: {
          product: {
            title: "Título",
            title_required: "Título é obrigatório",
            title_helper: "(Título enviado aos Marketplaces)",
            short_title: "Título Curto",
            category: "Categoria",
            brand: "Marca",
            model: "Modelo",
            nbm: "NBM/NCM",
            gender: "Gênero",
            volumes: "Volumes",
            manufacturer: "Fabricação",
            warranty_period: "Período de Garantia",
            months: "(Meses)",
            package_information: "INFORMAÇÕES DE EMBALAGEM",
            weight: "Peso",
            height: "Altura",
            width: "Largura",
            depth: "Profundidade",
            cubage: "Cubagem",
            description: "DESCRIÇÃO",
          }
        },
        modals: {
          import_product: {
            import_product: "Importar Produto",
            import_button: "Importar",
          },
          history: {
            product_history: "Histórico do Produto",
            close: "Fechar",
          },
          commentaries: {
            product_commentaries: "Comentários do Produto",
            digit_here: "Digite aqui",
            send: "Enviar",
          }
        },
        editor: {
          bold: "Negrito",
          italic: "Itálico",
          underline: "Sublinhado",
          help: "Serão publicados os valores máximos de caracteres permitidos por marketplace, sendo assim, descrições com limite ultrapassado serão cortadas!"
        }
      },
      variations: {
        no_variations: "Nenhuma variação encontrada",
        no_variations_descriptions: "Cadastre uma nova variação clicando no botão acima",
        product_variations: "VARIAÇÕES DO PRODUTO",
        characteristics_types: "Tipos de Características",
        add_variation: "Adicionar Variação",
        main_variation: "Variação Principal",
        variation: "Variação",
        main_image: "Imagem Principal",
        photos_inserted: "Fotos inseridas",
        upload_photos: "Carregar Fotos",
        primary_characteristic: "Característica Primária",
        secondary_characteristic: "Característica Secundária",
        select: "Selecione...",
        ref_code: "Código de Referência",
        bar_code: "Código de Barras",
        main_variation_tooltip: "Esta será a variação principal exibida no anúncio do produto.",
        variation_tooltip: "Variação que será exibida no anúncio do produto.",
        modals: {
          characteristics: {
            characteristics_type: "TIPOS DE CARACTERÍSTICAS",
            add_characteristics_type: "Adicionar Tipo de Características",
            name: "Nome",
            value: "Valor",
            add_feature: "Adicionar Característica"
          },
          product_association: {
            header: "Importante!",
            message: "Essa variação está vinculada a um pedido, portanto, não pode ser excluída."
          },
          duplicate_variation_gtin: {
            header: "Importante!",
            title: "Já existe uma variação com este EAN.",
            message: "Certifique-se de ter digitado corretamente ou inative a outra variação existente de mesmo EAN."
          },
          main_variation: {
            header: "Importante!",
            // nao tem nenhuma outra variacao ativa para ser a principal
            message: "Não é possível inativar a variação principal, pois não há outra variação ativa para ser a principal."
          }
        }
      },
      precification: {
        sku_group: "SKU Grupo",
        price_of: "Preço De",
        price_of_tooltip: "Preço bruto do produto, sem descontos, acréscimos e promoções!",
        discount: "Desconto",
        discount_tooltip: "Desconto aplicado no valor bruto do produto. Descontos, acréscimos e promoções realizadas na área de Campanhas não estão inclusos.",
        standard_price: "Preço Padrão",
        standard_price_tooltip: "Preço líquido do produto, não incluso descontos, acréscimos e promoções realizadas na área de Campanhas.",
        sku_standard_price_tooltip: "Preço da variação do produto sem a inclusão de descontos, acréscimos e promoções em vigor.",
        sku: "SKU",
        reference_code: "Código de Referência",
        primary_feature: "Característica Primária",
        secondary_feature: "Característica Secundária",
        voltage: "Voltagem",
        barcode: "Cód. de Barras",
        prices_practiced_in_marketplaces_title: "Preços praticados nos marketplaces",
        prices_practiced_in_marketplace_tooltip: "Preços praticados nos marketplaces já contendo descontos, acréscimos e promoções em vigor.",
        prices_practiced_in_marketplaces: {
          channel: "Canal",
          price: "Preço",
          note: "Observação"
        }
      },
      attributes: {
        attributes_by_marketplace: "Lista de marketplaces",
        category: "Categoria",
        attribute: "Atributo",
        value: "Valor",
        attribute_name_already_exists: "O nome do atributo já está sendo utilizado.",
        product_attributes: "Atributos do Produto",
        add_attribute: "Adicionar Atributo",
        search: "Pesquisar"
      },
      stock: {
        available_stock_by_sku_and_branch: "ESTOQUE DISPONÍVEL POR SKU E FILIAL",
        sku_group: "SKU Grupo",
        sku: "SKU",
        reference_code: "Código de Referência",
        primary_characteristic: "Característica Principal",
        secondary_characteristic: "Característica Secundária",
        gtin: "Código de Barras",
        available_total_sku_group: "Estoque Total Disponível SKU Grupo",
        minimum_stock_sku_group: "Estoque Mínimo SKU Grupo",
        available_total: "Total Disponível",
        minimum_stock: "Estoque Mínimo",
        restrictions_by_marketplace: "Restrições por Marketplace",
        total_available_tooltip: 'Soma do valor informado no campo "Total Disponível" de todas as variações ativas existentes.',
        default_minimum_stock_tooltip: 'Este valor será utilizado caso a variação não possua "Estoque Mínimo" informado.',
        available_stock_calculation_tooltip: 'Cálculo do campo "Estoque Disponível" menos os valores de "Estoque Mínimo" e "Estoque de Segurança" de todas as filiais ativas do SKU.',
        marketplace_sales_cutoff_tooltip: 'Quantidade de estoque do SKU que será considerado para efetuar o corte de vendas no marketplace.',
        stock_help_tooltip: 'Clique aqui para saber mais sobre as opções de estoque.',
        safety_stock_tooltip: 'Quantidade de estoque que será resguardado para fins de segurança entre "delay" de integrações.',
        crossdocking_lead_time_tooltip: 'Prazo adicional para vendas no formato Crossdocking.',
        available_stock_marketplace_tooltip: 'Será desconsiderado o valor do campo "Estoque Disponível" para marketplace(s) inativo(s) na variação em questão.',
        branch: {
          available_stock: "Estoque Disponível",
          stock_type: "Tipo de Estoque",
          safety_stock: "Estoque de Segurança",
          additional_lead_time: "Tempo Adicional de Expedição",
          select: "Selecione..."
        },
        stock_types: {
          automatic: "Automático",
          fixed: "Fixo",
          reducing: "Baixando"
        },
        modals: {
          help_stock: {
            help_stock: "Ajuda",
            subtitle: "TIPOS DE ESTOQUE",
            stock_automatic_highlight: "Automático – Interação via API:",
            stock_automatic_description: "Nesta opção o “Estoque Disponível” será atualizado via API.",
            stock_automatic_observation: "O prazo de crossdocking “Tempo Adicional” não se aplica a esta opção.",
            observation: "Observação:",
            observations: "Observações:",
            stock_fix_highlight: "Estoque Fixo:",
            stock_fix_description: "Nesta opção o “Estoque Disponível” será informado manualmente e não mudará se houver vendas.",
            stock_fix_observation: "O valor informado no campo “Prazo Adicional” de entrega será somado ao prazo de transporte.",
            reducing_stock: "Baixando Item do Estoque:",
            reducing_stock_description: "Nesta opção o “Estoque Disponível” será informado manualmente e diminuirá se houver vendas.",
            observation_1: "Existindo um cancelamento de pedido pendente, o estoque não irá aumentar automaticamente.",
            observation_2: "O valor informado no campo “Prazo Adicional” de entrega será somado ao prazo de transporte."
          },
          filter: {
            show: "Exibir:",
            active: "Ativo",
            inactive: "Inativo",
            variation_status: "Situação da Variação",
            branch_status: "Situação da Filial",
            primary_characteristic: "Característica Primaria",
            secondary_characteristic: "Característica Secundaria",
            sku: "SKU",
            reference_code: "Código de Referencia",
            ean: "Código de Barras",
          }
        }
      },
      restrictions: {
        ref_code: "Código de Referência",
        primary_characteristic: "Característica Prim.",
        secondary_characteristic: "Característica Sec.",
        barcode: "Cód. de Barras",
        marketplace_restrictions: "Restrições de Vendas por Marketplace",
        sales_restrictions_by_marketplace_tooltip: "Esta seção permite configurar restrições de vendas em determinados marketplaces.",
      },
      categories: {
        modals: {
          delete_category: {
            header: "Importante!",
            message: "Tem certeza de que deseja excluir esta categoria/subcategoria? Clicar em Sim ainda não aplicará as alterações."
          }
        },
        cannot_exclude_category_product: "Não é possível excluir uma categoria que possui produtos cadastrados.",
        cannot_exclude_category_last: "Não pode excluir porque é o único filho desta categoria.",
        cannot_exclude_category_unique_root: "Não pode excluir porque é a única categoria raiz.",
        title: "GESTÃO DE CATEGORIAS",
        search: "Pesquisar categorias...",
        associate_category: "Associar Categorias",
        add_category: "Adicionar Categoria",
        category_registered: "CATEGORIAS CADASTRADAS NO LINKHUB",
        my_categories: "Minhas Categorias",
        product_quantity: "Qnt. de Produtos",
        actions: "Ações",
        products: "Produto(s)",
      },
      associate_category: {
        modals: {
          dissasociateCategory: {
            header: "Importante!",
            message: "Deseja realmente excluir a associação selecionada? Ao clicar no botão Sim, as alterações serão imediatamente realizadas."
          },
          associateCategory: {
            associateMktpCategory: "Associar Categoria Marketplace"
          }
        },
        title: "ASSOCIAR CATEGORIAS",
        search: "Pesquisar categorias...",
        associate_with_mktp: "ASSOCIAR MINHAS CATEGORIAS COM ÀS DOS MARKETPLACES",
        associated_categories: "Categorias Associadas",
        no_associated_categories: "Categorias Não Associadas",
        categories: "Categorias",
        not_associated: "Não Associado",
        associated: "Associado",
        product_quantity: "Qnt. de Produtos",
        actions: "Ações",
        products: "Produto(s)",
      },
      publication_list: {
        modals: {
          filter: {
            sku_group: "SKU Grupo",
            sku: "SKU",
            practiced_price: "Preço Praticado",
            marketplaces: "Marketplace(s)",
            initial_price: "Preço Inicial",
            final_price: "Preço Final",
            stock: "Estoque",
            status: "Situação"
          },
          history: {
            header: "Histórico de Publicações do Produto",
            title: "Título",
            marketplace: "Marketplace",
            sku_group: "SKU Grupo",
            sku: "SKU",
            date_time: "Data/Hora",
            user: "Usuário",
            status: "Situação",
            description: "Descrição"
          }
        },
        status: {
          not_published: "Não Publicado",
          published: "Publicado",
          in_processing: "Em Processamento",
        },
        title: "PRODUTOS PARA PUBLICAÇÃO",
        title_tooltip: "É necessário o preenchimento dos campos Foto, Título, Categoria, Marca, Modelo, Descrição, Preço e Estoque para que o produto seja listado nesta área.",
        confirm_publish_products: "Deseja realmente Publicar os produtos selecionados? Ao clicar no botão Sim, as publicações serão imediatamente realizadas.",
        confirm_publish_products_header: "Importante!"
      }
    },
    settings: {
      logs: {
        system_logs: "LOGS DO SISTEMA",
        search_input: "Pesquisar logs...",
        module: "Módulo",
        period: "Período",
        no_logs: "Nenhum log encontrado",
        no_logs_description: "Nenhum log encontrado para o módulo selecionado",
      }
    },
    apps: {
      list: {
        apps_list: "LISTAGEM DE APLICATIVOS",
        develop_app: "Desenvolver APP",
        fast_filter: {
          title: "Filtros Rápidos",
          all: "Todos",
          installed_apps: "Aplicativos Instalados",
          e_commerce: "Marketplace",
          erps: "ERPs",
          transport: "Transporte",
          several: "Diversos",
        },
        certified_app: "APP HOMOLOGADO",
        not_certified_app: "APP NÃO HOMOLOGADO",
        evaluations: "Avaliações",
        configuration: "Configuração",
        usability: "Usabilidade",
        cost: "Custo",
        app_cost: "Custo do APP",
        free: "Grátis",
        paid_app: "Pago",
        developed_by: "Desenvolvido por",
        installed: "Instalado",
        get_app: "Obter APP",
        menu: {
          evaluate: "Avaliar",
          certify: "Homologar",
          edit: "Editar",
          uninstall: "Desinstalar",
        },
        confirm_desinstall_app_header: "Importante!",
        confirm_desinstall_app_message: "Deseja realmente desinstalar este aplicativo? Ao clicar no botão Sim, as alterações serão imediatamente realizadas.",
      },
      stepper: {
        step1: "Autenticação",
        step2: "Configuração",
        step3: "Finalização",
      }
    }
  },
  "ES": {
    loading: "Cargando...",
    select: "Seleccionar",
    success: "¡Éxito!",
    error: "Error!",
    content_copy: "Contenido copiado",
    error_content_copy: "Error al copiar contenido",
    error_img_200: "La imagen debe tener menos de 200x200 píxeles",
    error_copy_enterprise_data: "Error al copiar los datos de la empresa",
    copy_enterprise_data: "Haga clic aquí para repetir la empresa",
    error_api_call: "Error al llamar a la API",
    success_api_call: "Llamada API exitosa",
    authentication_error: "Error de autenticación",
    email_send_success: "Correo electrónico enviado con éxito",
    orders: {
      tables: {
        tracking: {
          tracking: "SEGUIMIENTO"
        },
        orders: {
          date_time: "Fecha/Hora",
          order: "Pedido",
          erp_order: "Pedido ERP",
          invoice: "Factura",
          status: "Situación",
          customer_cpf: "CPF/Cliente",
          amount: "Monto",
          payment_method: "Método de Pago",
          channel_order_id: "ID del Pedido del Canal",
          actions: "Acciones",
          app: "Aplicación",
        },
        personal_info: {
          personal_info: "INFORMACIÓN PERSONAL",
          delivery_address: "DIRECCIÓN DE ENTREGA",
          name: "Nombre",
          cpf_cnpj: "CPF/CNPJ",
          rg_ie: "RG/IE",
          birth_date: "Fecha de Nacimiento",
          marital_status: "Estado Civil",
          profession_industry: "Profesión/Rama",
          receiver: "Receptor",
          email: "Correo Electrónico",
          gender: "Género",
          mother_name: "Nombre de la Madre",
          spouse: "Cónyuge",
          partner: "Pareja",
          link: "Enlace",
          address: "Dirección",
          neighborhood: "Barrio",
          complement: "Complemento",
          zip_code: "Código Postal",
          city: "Ciudad",
          state: "Estado",
          phone: "Teléfono",
          mobile: "Celular"
        },
        order_items: {
          order_items: "ARTÍCULOS DEL PEDIDO",
          title: "Título",
          sku: "SKU",
          quantity: "Cantidad",
          unit_price: "Precio Unitario",
          total_value: "Valor Total",
          primary_feature: "Característica Primaria",
          secondary_feature: "Característica Secundaria",
          ean: "EAN",
          reference_code: "Código de Referencia",
          sub_total: "Sub Total",
          shipping_fee: "Gastos de Envío (+)",
          discount: "Descuento (-)",
          total: "Total"
        },
        billing_data: {
          billing_data: "DATOS DE FACTURACIÓN",
          billing_date: "Fecha de Facturación",
          branch_balance: "Sucursal Saldo",
          branch_invoice: "Sucursal Factura",
          erp_order: "Pedido ERP",
          invoice_number: "Número de Factura",
          invoice_key: "Clave de la Factura"
        },
        payment_data: {
          payment_data: "DATOS DE PAGO",
          marketplace: "Marketplace",
          total_value: "Valor Total",
          operation: "Operación",
          payment_method: "Método de Pago",
          card_brand: "Marca de la Tarjeta",
          payment_type: "Forma de Pago",
          marketplace_authorization: "Autorización Marketplace",
          intermediary_id: "Identificador del Intermediario",
          intermediary_cnpj: "CNPJ Intermediario Transacción",
          payment_institution_cnpj: "CNPJ Institución de Pago",
          installments: "Cuotas",
          installment: "Cuota",
          installment_value: "Valor Cuota",
          due_date: "Fecha de Vencimiento",
          paid_value: "Valor Pagado",
          payment_date: "Fecha de Pago",
          refund_value: "Valor Reembolso",
          refund_date: "Fecha de Reembolso",
          status: "Estado"
        },
        carrier: {
          carrier: "TRANSPORTISTA",
          transport_company: "Empresa de Transporte",
          delivery_deadline: "Plazo de Entrega",
          deliver_by: "Entregar hasta el día",
          freight_simulation_data: "Datos de Retorno de la Simulación de Flete",
          carrier_name: "Transportista",
          simulation: "Simulación",
          calculation_type: "Tipo de Cálculo",
          choice_code: "Código de Elección",
          protocol: "Protocolo"
        },
        comments: {
          comments: "COMENTARIOS",
          date_time: "Fecha/Hora",
          user: "Usuario",
          status: "Estado",
          message: "Mensaje"
        },
        history: {
          history: "HISTORIAL",
          date_time: "Fecha/Hora",
          user: "Usuario",
          description: "Descripción"
        }
      },
      modals: {
        edit_order: {
          order_edit: "Edición del Pedido",
          current_order_status: "Estado Actual del Pedido",
          new_order_status: "Nuevo Estado del Pedido",
          notes: "Observaciones",
          characters_used: "Caracteres utilizados:",
          files: "Archivo(s)",
          upload_files: "Cargar Archivo(s)",
          cancel: "Cancelar",
          save: "Guardar",
          status: {
            awaiting_approval: "Esperando Aprobación",
            approved: "Aprobado",
            awaiting_billing: "Esperando Facturación",
            billing: "Facturando",
            billed: "Facturado",
            in_transit: "En Tránsito",
            delivered: "Entregado",
            return_requested: "Devolución Solicitada",
            returning: "Devolviendo",
            returned: "Devuelto",
            canceled: "Cancelado",
            marketplace_canceled: "Cancelado por el Marketplace"
          },
          error_cannot_edit_order: "No se puede editar el pedido.",
        },
        tracking: {
          close: "Cerrar",
          tracking: "Seguimiento del pedido",
          system: "Sistema",
        },
        history: {
          history: "Historial del pedido"
        },
        comments: {
          order_comments: "Comentarios del Pedido",
          comment_message_placeholder: "Escribe tu comentario aquí...",
          send: "Enviar"
        }
      },
      details: {
        order_edit_button: "Actualizar Estado",
        order_info: "INFORMACIÓN DEL PEDIDO",
        order_title: "PEDIDO N°",
        back: "Volver",
        print: "Imprimir",
        order_tracking: "Rastreo del Pedido",
        expand_collapse: "Expandir/Colapsar",
        personal_info: "Información Personal",
        order_items: "Artículos del Pedido",
        billing_data: "Datos de Facturación",
        payment_data: "Datos de Pago",
        comments: "Comentarios",
        carrier: "Transportista",
        history: "Historial",
        viewed_by: "Visto el {date} por {user}",
      },
      orders_list: {
        title: 'LISTA DE PEDIDOS',
        search: 'Buscar pedidos...',
        filter: {
          form: {
            order_id: "Pedido",
            status: "Estado",
            date_interval: "Intervalo de Fechas",
            customer_cpf: "CPF/Cliente",
            payment_method: "Método de Pago",
            order_erp: "Pedido ERP",
            channel_order_id: "ID del Canal",
            invoice_number: "Número de Factura",
          }
        }
      }
    },
    subheader: {
      store: "Tienda",
      company: "Empresa",
      search: "Buscar empresas...",
      search_company_error: "Error al buscar empresas",
      search_store_error: "Error al buscar tiendas",
      no_records_found: "No se encontraron registros",
    },
    header: {
      leave: "Salir",
      notification: "Notificación",
      language: "Idioma",
      profile: "Perfil",
      invite_user: "Invitar Usuario",
      change_password: "Cambiar Contraseña",
    },
    sidebar: {
      open_menu: "Abrir menú",
      close_menu: "Cerrar menú",
      item_home: "Home",
      item_company: "Compañía",
      item_products: "Productos",
      item_users: "Usuarios",
      item_orders: "Pedidos",
      item_config: "Configuración",
      settings_log: "Log del Sistema",
      item_app: "Aplicaciones",
      item_help: "Ayuda",
      item_categories: "Categorías",
      item_publications: "Publicaciones",
    },
    breadcrumb: {
      home: "Home",
      company: "Compañía",
      products: "Productos",
      users: "Usuarios",
      orders: "Pedidos",
      monitoring: "Monitoreo",
      app: "Aplicaciones",
      help: "Ayuda",
      company_tabs: {
        stores: "Tiendas",
        branches: "Sucursales"
      },
      users_profiles: "Perfiles",
      register_product: "Registro",
      edit_product: "Editar",
      product_variations: "Registro",
      edit_product_variations: "Editar",
      product_attributes: "Registro",
      edit_product_attributes: "Editar",
      product_precification: "Registro",
      edit_product_precification: "Editar",
      product_stock: "Registro",
      edit_product_stock: "Editar",
      product_restrictions: "Registro",
      edit_product_restrictions: "Editar",
      settings: "Configuraciones",
      settings_tabs: {
        logs: "Logs del Sistema",
      },
      order_detail: "Detalle",
      apps: "Aplicaciones",
      product_categories: "Categorías",
      product_categories_associate: "Categorías",
      product_publications: "Publicaciones",
      app_installation: "Aplicaciones"
    },
    company: {
      tabs: {
        company: "COMPAÑÍA",
        stores: "TIENDAS",
        branches: "SUCURSALES",
      },
      forms: {
        company: {
          corporate_name: "Razón Social",
          corporate_name_required: "Razón Social es obligatorio",
          trade_name: "Nombre Fantasía",
          trade_name_required: "Nombre Fantasía es obligatorio",
          sistem_name: "Nombre en el Sistema",
          sistem_name_required: "Nombre en el Sistema es obligatorio",
          cnpj: "CNPJ",
          cnpj_required: "CNPJ es obligatorio",
          ie: "Inscripción Estatal",
          postal_code: "Código Postal",
          postal_code_required: "Código Postal es obligatorio",
          address: "Dirección",
          address_required: "Dirección es obligatorio",
          number: "Número",
          number_required: "Número es obligatorio",
          neighborhood: "Barrio",
          neighborhood_required: "Barrio es obligatorio",
          address_complement: "Complemento",
          city: "Ciudad",
          city_required: "Ciudad es obligatorio",
          state: "Estado",
          state_required: "Estado es obligatorio",
          phone: "Teléfono",
          country: "País",
          country_required: "País es obligatorio",
          currency: "Moneda",
          select_image: "Seleccione una imagen aquí!",
        },
        responsible: {
          name: "Nombre",
          department: "Departamento",
          email: "E-mail",
          phone: "Teléfono",
          cellphone: "Celular",
          name_required: "Nombre es obligatorio",
          email_required: "E-mail es obligatorio",
          email_invalid: "E-mail inválido",
          department_required: "Departamento es obligatorio",
        }
      },
      tables: {
        managers: {
          name: "Nombre",
          department: "Departamento",
          email: "E-mail",
          phone: "Teléfono",
          cellphone: "Celular",
          id: "Código"
        },
        stores: {
          store_id: "Cód.",
          store_name: "Nombre",
          city: "Ciudad",
          state: "Estado",
          responsible: "Responsable",
          email: "E-mail",
          cellphone: "Celular",
          phone: "Teléfono",
          status: "Estado",
          actions: "Acciones",
        }
      },
      modals: {
        delete_responsible: {
          header: "¡Importante!",
          message: "¿Realmente desea eliminar este responsable? Al hacer clic en el botón Sí, los cambios se realizarán de inmediato."
        },
        inactivate_company: {
          header: "¡Importante!",
          message: "¿Realmente desea inactivar esta empresa? Al hacer clic en el botón Sí, los cambios se realizarán de inmediato."
        },
        new_responsible: {
          header: "Nuevo Responsable",
          button_cancel: "Cancelar",
          button_save: "Guardar",
          button_back: "Volver"
        },
        edit_reponsible: {
          header: "Editar Responsable",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        }
      },
      button_yes: "Sí",
      button_no: "No",
      active: "activo",
      inactive: "inactivo",
      button_edit: "Editar",
      button_new_responsible: "Nuevo Responsable",
      general_information: "INFORMACIÓN GENERAL",
      managers: "Responsables",
      no_stores: "No hay tiendas registradas",
      no_stores_description: "Registre una nueva tienda haciendo clic en el botón de arriba",
    },
    company_store: {
      forms: {
        new_store: {
          store_name: "Nombre de la Tienda",
          responsible: "Responsable",
          email: "E-mail",
          phone: "Teléfono",
          city: "Ciudad",
          state: "Estado",
          cellphone: "Celular",
        }
      },
      modals: {
        new_store: {
          header: "Nueva Tienda",
          button_cancel: "Cancelar",
          button_save: "Guardar",
          store_name_required: "Nombre de la tienda es obligatorio",
          city_required: "Ciudad es obligatorio",
          state_required: "Estado es obligatorio",
          email_invalid: "E-mail inválido",
        },
        edit_store: {
          header: "Editar Tienda",
        }
      },
      store_list: "LISTADO DE TIENDAS",
      button_filter: "Filtros",
      button_add_store: "Agregar Tienda",
      fetch_store_error: "Error al buscar tiendas",
      error_no_enterprise: "No se ha seleccionado ninguna empresa",
    },
    company_branches: {
      tables: {
        branches: {
          id: "Código",
          name: "Nombre",
          tax_id: "CNPJ",
          type: "Tipo de sucursal",
          city: "Ciudad",
          state: "Estado",
          responsible: "Responsable",
          store: "Tienda",
          status: "Estado",
          actions: "Acciones",
          branch_types: {
            physical_store: "Tienda Física",
            pickup_point: "Punto de Retiro",
            distribution_center: "Centro de Distribución",
            head_office: "Sede",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      modals: {
        new_branch: {
          header: "Nueva Sucursal",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        },
        edit_branch: {
          header: "Editar Sucursal",
          button_cancel: "Cancelar",
          button_save: "Guardar",
        },
        filter: {
          show: "",
          form: {
            active: "Activo",
            inactive: "Inactivo",
            name: "Nombre",
            tax_identifier: "CIF",
            city: "Ciudad",
            responsible: "Responsable",
            uf: "Provincia",
            store: "Tienda",
            branch_type: "Tipo de Sucursal",
          }
        }
      },
      forms: {
        new_branch: {
          tax_identifier: "CNPJ",
          tax_identifier_required: "CNPJ es obligatorio",
          branch_name: "Nombre de la Sucursal",
          branch_name_required: "Nombre de la Sucursal es obligatorio",
          postal_code: "Código Postal",
          postal_code_required: "Código Postal es obligatorio",
          address: "Dirección",
          address_required: "Dirección es obligatorio",
          address_complement: "Complemento",
          city: "Ciudad",
          city_required: "Ciudad es obligatorio",
          state: "Estado",
          state_required: "Estado es obligatorio",
          state_placeholder: "Seleccione un estado",
          state_registration: "Inscripción Estatal",
          responsable: "Responsable",
          store: "Tiendas",
          store_required: "Tienda es obligatorio",
          store_placeholder: "Seleccione una tienda",
          stores_no_result: "No hay tiendas registradas",
          branch_type: "Tipo de Sucursal",
          number: "Número",
          number_required: "Número es obligatorio",
          branch_type_placeholder: "Seleccione un tipo de sucursal",
          branch_type_required: "Tipo de Sucursal es obligatorio",
          branch_types: {
            physical_store: "Tienda Física",
            pickup_point: "Punto de Retiro",
            distribution_center: "Centro de Distribución",
            head_office: "Sede",
            fulfillment_center: "Centro de Fulfillment",
          }
        }
      },
      error_search_branches: "Error al buscar sucursales",
      empty_branches: "No se encontraron sucursales",
      empty_branches_description: "Registre una nueva sucursal haciendo clic en el botón de arriba",
      filial_list: "LISTADO DE SUCURSALES",
      button_add_branch: "Agregar Sucursal",
      filters: "Filtros"
    },
    users: {
      tables: {
        users: {
          id: "Código",
          name: "Nombre",
          email: "E-mail",
          role: "Perfil",
          department: "Departamento",
          status: "Estado",
          actions: "Acciones",
        }
      },
      modals: {
        newUser: {
          newUser: "Nuevo Usuario",
          photo_size_recommend: "Recomendamos una imagen de 300x300 píxeles",
          alt_img_preview: "Vista previa de la imagen",
        },
        editUser: {
          editUser: "Editar Usuario",
          photo_size_recommend: "Recomendamos una imagen de 300x300 píxeles",
          alt_img_preview: "Vista previa de la imagen",
        }
      },
      forms: {
        new_user: {
          name: "Nombre",
          email: "E-mail",
          profile: "Perfil de Acceso",
          stores: "Tiendas",
          realesed_stores: "Tiendas Liberadas",
          department: "Departamento",
          name_required: "Nombre es obligatorio",
          email_required: "E-mail es obligatorio",
          email_invalid: "E-mail inválido",
          stores_required: "Tiendas es obligatorio",
          department_required: "Departamento es obligatorio",
          profile_required: "Perfil es obligatorio",
        }
      },
      no_users: "No se encontraron usuarios",
      no_users_description: "Registre un nuevo usuario haciendo clic en el botón de arriba",
      fetch_users_error: "Error al buscar usuarios",
      users_list: "LISTADO DE USUARIOS",
      search: "Buscar usuarios...",
      button_access_profiles: "Perfiles de Acceso",
      button_add_user: "Agregar Usuario",
    },
    users_profiles: {
      profile_management: "GESTIÓN DE PERFILES",
      button_duplicate_profile: "Duplicar Perfil",
      button_add_profile: "Agregar Perfil",
      available_options: "Opciones Disponibles",
      options_released: "Opciones Liberadas",
      clear_selection: "Quitar selección",
      profile_list: "LISTA DE PERFILES",
      list_of_functionalities: "LISTA DE FUNCIONALIDADES"
    },
    auth: {
      login: {
        email: "E-mail",
        password: "Contraseña",
        no_account: "¿Aún no tienes una cuenta?",
        no_account_click_here: "Haz clic aquí",
        forgot_my_password: "Olvidé mi contraseña",
        log_in: "INICIAR SESIÓN"
      },
      forms: {
        login: {
          email_required: "El e-mail es obligatorio.",
          password_required: "La contraseña es obligatoria",
          email_invalid: "Correo electrónico inválido."
        },
        reset_password: {
          email_required: "El e-mail es obligatorio.",
          email_invalid: "Correo electrónico inválido."
        },
        reset_token: {
          password_required: "La contraseña es obligatoria",
          password_confirmation_required: "La confirmación de la contraseña es obligatoria",
          password_confirmation_same: "Las contraseñas no coinciden",
          password_confirmation_invalid: "La contraseña debe tener al menos 8 caracteres",
          password_min: "La contraseña debe tener al menos 8 caracteres"
        }
      },
      reset_password: {
        title: "Restablecer contraseña",
        confirm: "RESTABLECER",
        return: "para volver a la pantalla de inicio de sesión.",
        click_here: "Haz clic aquí"
      },
      register: {
        email: "Correo electrónico",
        password: "Contraseña",
        sign_up: "REGISTRARSE",
        department: "Departamento",
        name: "Nombre",
        confirm_password: "Confirmar Contraseña"
      },
      reset_token: {
        new_password: "Nueva Contraseña",
        confirm_new_password: "Confirmar Nueva Contraseña",
      }
    },
    errors: {
      unexpected_error: {
        title: "¡ERROR INESPERADO!",
        description: "Se produjo un error inesperado en nuestro sistema, inténtelo nuevamente más tarde."
      }
    },
    products: {
      modals: {
        confirm_cancel: "¿Realmente desea descartar los cambios realizados? Al hacer clic en Sí, los cambios serán descartados de inmediato."
      },
      tabs: {
        general_data: "DATOS GENERALES",
        variations: "VARIACIONES",
        attributes: "ATRIBUTOS",
        precification: "PRECIFICACIÓN",
        stock: "STOCK",
        restrictions: "RESTRICCIONES",
      },
      products_list: {
        modals: {
          filter: {
            close_filter: "Cerrar Filtros",
            show: "Mostrar:",
            active: "Activos",
            inactive: "Inactivos",
            form: {
              sku_group: "Grupo SKU",
              product_type: "Tipo de Producto",
              category: "Categoría",
              brand: "Marca",
              price: "Precio",
              initial_amount: "Valor Inicial",
              final_amount: "Valor Final",
              stock: "Inventario",
              initial_quantity: "Cantidad Inicial",
              final_quantity: "Cantidad Final",
              model: "Modelo",
            },
            apply_filters: "Aplicar Filtros",
            clean_filters: "Limpiar Filtros",
          },
          variations: {
            product_variations: "Variaciones del Producto",
            sku_group: "Grupo SKU",
            title: "Título",
            table: {
              ref_code: "Código de Referencia",
              ean: "Código de Barras (EAN)",
              primary_characteristic: "Característica Primaria",
              secondary_characteristic: "Característica Secundaria",
              active: "Activo",
              inactive: "Inactivo",
            },
            close: "Cerrar",
            access_product: "Acceder al Producto",
          }
        },
        tables: {
          products: {
            sku_group: "Grupo SKU",
            image: "Imagen",
            title: "Título",
            product_type: "Tipo de Producto",
            kit_variation: "Kit con Variación",
            variation: "Con Variación",
            simple: "Simple",
            category: "Categoría",
            brand: "Marca",
            model: "Modelo",
            standard_price: "Precio Estándar",
            total_stock: "Inventario Total",
          }
        },
        title: "LISTADO DE PRODUCTOS",
        search: "Buscar productos...",
        filters: "Filtros",
        publications: "Publicaciones",
        add_product: "Agregar Producto",
        no_products: "No se encontraron productos",
        no_products_description: "Registre un nuevo producto haciendo clic en el botón de arriba",
        no_products_selected: "No se seleccionaron productos"
      },
      details: {
        no_categories: "No hay categorías registradas",
        select_category: "Seleccionar una categoría",
        select_gender: "Seleccione un género",
        select_manufactorer: "Seleccione un fabricante",
        no_category: "No se encontró ninguna categoría",
        product_details: "DATOS GENERALES DEL PRODUCTO",
        history: "Historial",
        import_product: "Importar Producto",
        select_image: "Agregue imágenes en la pestaña Variaciones",
        sku_group: "Grupo SKU",
        product_status: "Estado del Producto",
        used_characteres: "Caracteres utilizados",
        months: "Meses",
        month: "Mes",
        forms: {
          product: {
            title: "Título",
            title_required: "Título es obligatorio",
            title_helper: "(Título enviado a los Marketplaces)",
            short_title: "Título Corto",
            category: "Categoría",
            brand: "Marca",
            model: "Modelo",
            nbm: "NBM/NCM",
            gender: "Género",
            volumes: "Volúmenes",
            manufacturer: "Fabricación",
            warranty_period: "Período de Garantía",
            months: "(Meses)",
            package_information: "INFORMACIÓN DE EMBALAJE",
            weight: "Peso",
            height: "Altura",
            width: "Ancho",
            depth: "Profundidad",
            cubage: "Cubaje",
            description: "DESCRIPCIÓN",
          }
        },
        modals: {
          import_product: {
            import_product: "Importar Producto",
            import_button: "Importar",
          },
          history: {
            product_history: "Historial del Producto",
            close: "Cerrar",

          },
          commentaries: {
            product_commentaries: "Comentarios del Producto",
            digit_here: "Digite aquí...",
            send: "Enviar",
          }
        },
        editor: {
          bold: "Negrita",
          italic: "Itálico",
          underline: "Subrayado",
          help: "Se publicarán los valores máximos de caracteres permitidos por marketplace, por lo tanto, las descripciones con límite excedido se cortarán."
        }
      },
      variations: {
        no_variations: "No se encontraron variaciones",
        no_variations_descriptions: "Registre una nueva variación haciendo clic en el botón de arriba",
        product_variations: "VARIACIONES DEL PRODUCTO",
        characteristics_types: "Tipos de Características",
        add_variation: "Agregar Variación",
        main_variation: "Variación Principal",
        variation: "Variación",
        main_image: "Imagen Principal",
        photos_inserted: "Fotos insertadas",
        upload_photos: "Cargar Fotos",
        primary_characteristic: "Característica Primaria",
        secondary_characteristic: "Característica Secundaria",
        select: "Seleccionar...",
        ref_code: "Código de Referencia",
        bar_code: "Código de Barras",
        main_variation_tooltip: "Esta será la variación principal mostrada en el anuncio del producto.",
        variation_tooltip: "Esta variación será mostrada en el anuncio del producto.",
        modals: {
          characteristics: {
            characteristics_type: "TIPOS DE CARACTERÍSTICAS",
            add_characteristics_type: "Agregar Tipo de Características",
            name: "Nombre",
            value: "Valor",
            add_feature: "Agregar Característica",
          },
          product_association: {
            header: "¡Importante!",
            message: "Esta variación está vinculada a un pedido, por lo que no puede ser eliminada."
          },
          duplicate_variation_gtin: {
            header: "¡Importante!",
            title: "Ya existe una variación con este EAN.",
            message: "Asegúrese de haberlo ingresado correctamente o desactive la otra variación existente con el mismo EAN."
          },
          main_variation: {
            header: "¡Importante!",
            message: "No hay ninguna otra variación activa para ser la principal."
          }
        }
      },
      precification: {
        sku_group: "Grupo de SKU",
        price_of: "Precio De",
        price_of_tooltip: "Precio bruto del producto, sin descuentos, recargos ni promociones.",
        discount: "Descuento",
        discount_tooltip: "Descuento aplicado al valor bruto del producto. Los descuentos, recargos y promociones realizadas en el área de Campañas no están incluidos.",
        standard_price: "Precio Estándar",
        standard_price_tooltip: "Precio neto del producto, sin incluir descuentos, recargos y promociones realizadas en el área de Campañas.",
        sku_standard_price_tooltip: "Precio de la variación del producto sin incluir descuentos, recargos y promociones vigentes.",
        sku: "SKU",
        reference_code: "Código de Referencia",
        primary_feature: "Característica Principal",
        secondary_feature: "Característica Secundaria",
        voltage: "Voltaje",
        barcode: "Código de Barras",
        standard_price_per_sku: "Precio Estándar por SKU",
        prices_practiced_in_marketplaces_title: "Precios practicados en los marketplaces",
        prices_practiced_in_marketplace_tooltip: "Precios aplicados en los marketplaces ya incluyen descuentos, recargos y promociones vigentes.",
        prices_practiced_in_marketplaces: {
          channel: "Canal",
          price: "Precio",
          note: "Observación"
        }
      },
      attributes: {
        attributes_by_marketplace: "Lista de Marketplaces",
        category: "Categoría",
        attribute: "Atributo",
        value: "Valor",
        attribute_name_already_exists: "El nombre del atributo ya está siendo utilizado.",
        product_attributes: "Atributos del Producto",
        add_attribute: "Agregar Atributo",
        search: "Buscar"
      },
      stock: {
        available_stock_by_sku_and_branch: "STOCK DISPONIBLE POR SKU Y SUCURSAL",
        sku_group: "SKU Grupo",
        sku: "SKU",
        reference_code: "Código de Referencia",
        primary_characteristic: "Característica Principal",
        secondary_characteristic: "Característica Secundaria",
        gtin: "Código de Barras",
        available_total_sku_group: "Inventario Total Disponible SKU Grupo",
        minimum_stock_sku_group: "Inventario Mínimo SKU Grupo",
        available_total: "Total Disponible",
        minimum_stock: "Stock Mínimo",
        restrictions_by_marketplace: "Restricciones por Marketplace",
        total_available_tooltip: 'Suma del valor ingresado en el campo "Total Disponible" de todas las variaciones activas existentes.',
        default_minimum_stock_tooltip: 'Este valor se utilizará en caso de que la variación no tenga "Stock Mínimo" informado.',
        available_stock_calculation_tooltip: 'Cálculo del campo "Stock Disponible" menos los valores de "Stock Mínimo" y "Stock de Seguridad" de todas las sucursales activas del SKU.',
        marketplace_sales_cutoff_tooltip: 'Cantidad de stock del SKU que se considerará para efectuar el corte de ventas en el marketplace.',
        stock_help_tooltip: 'Haga clic aquí para saber más sobre las opciones de stock.',
        safety_stock_tooltip: 'Cantidad de stock que se reservará por motivos de seguridad entre los "delays" de integraciones.',
        crossdocking_lead_time_tooltip: 'Plazo adicional para ventas en formato Crossdocking.',
        available_stock_marketplace_tooltip: 'Se desconsiderará el valor del campo "Stock Disponible" para marketplace(s) inactivo(s) en la variación en cuestión.',
        branch: {
          available_stock: "Stock Disponible",
          stock_type: "Tipo de Stock",
          safety_stock: "Stock de Seguridad",
          additional_lead_time: "Tiempo Adicional de Expedición",
          select: "Seleccione..."
        },
        stock_types: {
          automatic: "Automático",
          fixed: "Fijo",
          reducing: "Reduciendo"
        },
        modals: {
          help_stock: {
            help_stock: "Ayuda",
            subtitle: "TIPOS DE STOCK",
            stock_automatic_highlight: "Automático – Interacción vía API:",
            stock_automatic_description: "En esta opción, el “Stock Disponible” se actualizará vía API.",
            stock_automatic_observation: "El plazo de crossdocking “Tiempo Adicional” no se aplica a esta opción.",
            observation: "Observación:",
            observations: "Observaciones:",
            stock_fix_highlight: "Stock Fijo:",
            stock_fix_description: "En esta opción, el “Stock Disponible” se informará manualmente y no cambiará si hay ventas.",
            stock_fix_observation: "El valor informado en el campo “Plazo Adicional” de entrega se sumará al plazo de transporte.",
            reducing_stock: "Reduciendo el Stock:",
            reducing_stock_description: "En esta opción, el “Stock Disponible” se informará manualmente y disminuirá si hay ventas.",
            observation_1: "Si hay una cancelación de pedido pendiente, el stock no aumentará automáticamente.",
            observation_2: "El valor informado en el campo “Plazo Adicional” de entrega se sumará al plazo de transporte."
          },
          filter: {
            show: "Mostrar:",
            variation_status: "Estado de la Variación",
            branch_status: "Estado de la Sucursal",
            primary_characteristic: "Característica Primaria",
            secondary_characteristic: "Característica Secundaria",
            sku: "SKU",
            reference_code: "Código de Referencia",
            ean: "Código de Barras",
            active: "Activo",
            inactive: "Inactivo",
          }
        }
      },
      restrictions: {
        ref_code: "Código de Referencia",
        primary_characteristic: "Característica Primaria",
        secondary_characteristic: "Característica Secundaria",
        barcode: "Código de Barras",
        marketplace_restrictions: "Restricciones de Ventas por Marketplace",
        sales_restrictions_by_marketplace_tooltip: "Esta sección permite configurar restricciones de ventas en mercados específicos.",
      },
      categories: {
        modals: {
          delete_category: {
            header: "¡Importante!",
            message: "¿Está seguro de que desea eliminar esta categoría/subcategoría? Al hacer clic en Sí, los cambios aún no se aplicarán."
          }
        },
        cannot_exclude_category_product: "No se puede excluir una categoría que tiene productos registrados.",
        cannot_exclude_category_last: "No se puede excluir porque es el único hijo de esta categoría.",
        cannot_exclude_category_unique_root: "No se puede excluir la única categoría raíz.",
        title: "GESTIÓN DE CATEGORÍAS",
        search: "Buscar categorías...",
        associate_category: "Asociar Categoría",
        add_category: "Agregar Categoría",
        category_registered: "Categorías registradas en LinkHub",
        my_categories: "Mis Categorías",
        product_quantity: "Cantidad de Productos",
        actions: "Acciones",
        products: "Producto(s)"
      },
      associate_category: {
        modals: {
          dissasociateCategory: {
            header: "¡Importante!",
            message: "¿Realmente desea eliminar la asociación seleccionada? Al hacer clic en el botón Sí, los cambios se aplicarán inmediatamente."
          },
          associateCategory: {
            associateMktpCategory: "Asociar Categoría de Marketplace"
          }
        },
        title: "ASIGNAR CATEGORÍAS",
        search: "Buscar categorías...",
        associate_with_mktp: "ASOCIAR MIS CATEGORÍAS CON LAS DE LOS MARKETPLACES",
        associated_categories: "Categorías Asociadas",
        no_associated_categories: "Categorías no asociadas",
        categories: "Categorías",
        not_associated: "No asociado",
        associated: "Asociado",
        product_quantity: "Cantidad de Productos",
        actions: "Acciones",
        products: "Producto(s)"
      },
      publication_list: {
        modals: {
          filter: {
            sku_group: "Grupo de SKU",
            sku: "SKU",
            practiced_price: "Precio Practicado",
            marketplaces: "Marketplace(s)",
            initial_price: "Precio Inicial",
            final_price: "Precio Final",
            stock: "Stock",
            status: "Situación"
          },
          history: {
            header: "Historial de Publicaciones del Producto",
            title: "Title",
            marketplace: "Marketplace",
            sku_group: "SKU Group",
            sku: "SKU",
            date_time: "Date/Time",
            user: "User",
            status: "Status",
            description: "Description"
          }
        },
        status: {
          not_published: "No Publicado",
          published: "Publicado",
          in_processing: "En Procesamiento",
        },
        title: "PRODUCTOS PARA PUBLICAR",
        title_tooltip: "Es necesario completar los campos Foto, Título, Categoría, Marca, Modelo, Descripción, Precio y Stock para que el producto sea listado en esta área.",
        confirm_publish_products: "¿Realmente desea publicar los productos seleccionados? Al hacer clic en el botón Sí, los cambios se aplicarán inmediatamente.",
        confirm_publish_products_header: "¡Importante!",
      }
    },
    settings: {
      logs: {
        system_logs: "LOGS DEL SISTEMA",
        search_input: "Buscar logs...",
        module: "Módulo",
        period: "Período",
        no_logs: "No se encontraron logs",
        no_logs_description: "No se encontraron logs para el módulo seleccionado",
      }
    },
    apps: {
      list: {
        apps_list: "LISTA DE APLICACIONES",
        develop_app: "Desarrollar APP",
        fast_filter: {
          title: "Filtros Rápidos",
          all: "Todos",
          installed_apps: "Aplicaciones instaladas",
          e_commerce: "Marketplace",
          erps: "ERPs",
          transport: "Transporte",
          several: "Varios",
        },
        certified_app: "APP CERTIFICADA",
        not_certified_app: "APP NO CERTIFICADA",
        evaluations: "Evaluaciones",
        configuration: "Configuración",
        usability: "Usabilidad",
        cost: "Costo",
        app_cost: "Costo de la APP",
        free: "Gratis",
        paid_app: "Pago",
        developed_by: "Desarrollado por",
        installed: "Instalado",
        get_app: "Obtener APP",
        menu: {
          evaluate: "Evaluar",
          certify: "Certificar",
          edit: "Editar",
          uninstall: "Desinstalar",
        },
        confirm_desinstall_app_header: "¡Importante!",
        confirm_desinstall_app_message: "¿Realmente desea desinstalar esta aplicación? Al hacer clic en Sí, los cambios se realizarán de inmediato.",
      },
      stepper: {
        step1: "Autenticación",
        step2: "Configuración",
        step3: "Finalización",
      }
    }
  },
}

const i18n = createI18n({
  locale: "pt_BR",
  fallbackLocale: "EN",
  messages,
  silentTranslationWarn: true,
  fallbackWarn: false,
  missingWarn: false
});

export default i18n;